/* eslint-disable prettier/prettier */
import AsyncStorage from "@react-native-async-storage/async-storage";
import {APIadminsURL} from "@env";
import axios from "axios";

import { Text,    } from "react-native-web";
import { useNavigate, Route, Routes,useLocation  } from "react-router-dom";
import Navbar from "../../components/navbar/index.web";
import ForgetPassword from "../authentication/forgetpassword";
import LoginPage from "../authentication/login";
import SignupPage from "../authentication/signup";
import Dashboard from "../main/dashboard";
import Dataroom from "../main/dataroom";
import Documents from "../main/documents";
import Emails from "../main/emails"
import Investments from "../main/investments";
import ProfilePage from "../main/profile";
import DocumentDisplay from "../main/documentDisplay";
import SubStepper from "../subscription/substepper";
import WelcomeSub from "../subscription/welcome";
import Summary from "../subscription/summary";
import InvestmentsPage from "../Investments";
import All_investments from "../main/All_investments";
import Test from "../test";
import { useEffect, useState } from "react";

import TestParams from "../test3params";
import TosDumper from "../tosdumper/tos";
import PpDumper from "../tosdumper/pp";
import Payment from "../subscription/payment";

function CustomRouting({ routes }) {
  const [isLoggedIn , setIsLoggedIn ] = useState(null);
  const location = useLocation();
  const navigate = useNavigate();


  useEffect(() => {
   

    const containsDatarome = location.pathname.includes('Dataroom') || location.pathname.includes('signup') || location.pathname.includes('privacypolicy') || location.pathname.includes('tos') || location.pathname.includes('login');
  
    if (!containsDatarome) {
      //console.log("'datarome' exists in the pathname");
  
    setIsLoggedIn(null);

    AsyncStorage.getItem("token").then((res) => {
      if (res === null) {
        navigate("/");
        setIsLoggedIn(false);

      } else {
        axios
          .get(`${APIadminsURL}/public/api/check-auth`, {
            headers: { Authorization: `Bearer ${res}` },
          })
          .then((res) => {
            setIsLoggedIn(true);
          })
          .catch(() => {
            navigate("/login");
            setIsLoggedIn(false);

          });
      }
    });
   
  }else{
    setIsLoggedIn(false);
  }
  }, []);



  if (isLoggedIn === null) {
    return (
  
        <Text>Loading...</Text>
      
    );
  }
 
  return (
      <Routes>
        <Route path="/" element={<Navbar ><InvestmentsPage /></Navbar>} />
        <Route path="/signup" element={<SignupPage />} />
        <Route path="/login" element={<LoginPage />} />
        <Route path="/forget-password" element={<ForgetPassword />} />
        <Route path="/dashboard" element={<Navbar menuindex={0}><Dashboard /></Navbar> } />
        <Route path="/All_investments" element={<Navbar menuindex={0}><All_investments /></Navbar>} />
        <Route path="/DocumentDisplay" element={<DocumentDisplay/>} />
        <Route path="/investments" element={<Navbar menuindex={1}><Investments /></Navbar>} />
        <Route path="/investments/:id" element={<Navbar menuindex={1}><Dataroom /></Navbar>} />
        <Route path="/Dataroom/:id/:dataRoom" element={<Dataroom />} />
        <Route path="/start/:id" element={<Navbar menuindex={1}><WelcomeSub /></Navbar>} />
        <Route path="/documents" element={<Navbar menuindex={2}><Documents /></Navbar>} />
        <Route path="/emails" element={<Navbar menuindex={3}><Emails /></Navbar>} />
        <Route path="/profile" element={<Navbar><ProfilePage /></Navbar>} />
        <Route path="/subscription/:investment/:id" element={<Navbar><SubStepper /></Navbar>} />
        <Route path="/summary/:investment/:id" element={<Navbar menuindex={1} title="Investments"><Summary /></Navbar>} />
        <Route path="/payment/:investment/:id" element={<Navbar menuindex={1} title="payment"><Payment /></Navbar>} />
        <Route path="/tos" element={<TosDumper />} />
        <Route path="/privacypolicy" element={<PpDumper />} />

        <Route path="/sec" element={<Test />} />
        <Route path="/three/:id" element={<TestParams />} />
        <Route path="*" element={<Text>the best 404 page a humble man could wish for</Text>} />
      </Routes>
  );
}

export default CustomRouting;
