import { Button } from "@ui-kitten/components";
import { LinearGradient } from "expo-linear-gradient";
import { Dimensions, Image, Text, TouchableOpacity, View } from "react-native";
import { Icon } from "react-native-eva-icons";
import { useNavigate } from "react-router-native";
import {APIadminsURL} from "@env";
function Card({
  id,
  name,
  status,
  equity_goal,
  min_invest,
  max_invest,
  closing_date,
  photo,
  street_name,
  city,
  country,
  types,
  seeall = false,
  height = "100%",
  onClick,
  login
}) {
  //const navigate = useNavigate();
  function currency(num) {
    if (num)
      return num.toLocaleString("en-US", {
        style: "currency",
        currency: "USD",
        maximumFractionDigits: 0,
      });
  }
  const width = Dimensions.get("window").width;
  const ismobile = width < 768;
  return (
    <TouchableOpacity
      key={id+565}
      onPress={() => {
        onClick(id);
      }}
      style={{
        width: ismobile ? "90%" : "100%",
        maxWidth: 400,
        minWidth: ismobile ? null : 400,
        height,
        alignSelf: "center",
        display: "flex",
        flexDirection: "column",
        marginHorizontal: ismobile ? 10 : 0,
        marginBottom: 10,
        borderRadius: 15,
        backgroundColor: "#fff",
      }}>
      <View  key={id+544444065} style={{ width: "100%", height: 190, borderRadius: 10 }}>
        <Image   key={id+5112121265}
          source={{
            uri: photo
              ? `${APIadminsURL}/img/investments/` + photo
              : "https://images.pexels.com/photos/1396122/pexels-photo-1396122.jpeg?auto=compress&cs=tinysrgb&dpr=1&w=500  ",
          }}
          style={{ width: "100%", height: 210, borderTopLeftRadius: 10, borderTopRightRadius: 10 }}
        />
        <LinearGradient   key={id+1414565}
          colors={["transparent", "rgba(0,0,0,1)"]}
          style={{
            position: "relative",
            top: -65,
            height: 65,
            paddingHorizontal: 15,
            paddingVertical: 10,
          }}>
          <Text
            style={{
              fontFamily: "Roboto_700Bold",
              color: "#fff",
              fontSize: 26,
              marginTop: -8,
              marginBottom: 3,
            }}>
            {name}
          </Text>

          <View
            key={id+5777765}
            style={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
            }}>
            <Icon name="pin-outline" width={20} height={20} fill="#fff" />
            <Text style={{ fontFamily: "Roboto_500Medium", color: "#fff", marginLeft: 5 }}>
              {street_name ? street_name + ", " : ""}
             
              {city ? city + ", " : ""}
            
              {country ? country  : ""}
            </Text>
          </View>
        </LinearGradient>
        <View
          key={id+5674105}
          style={{
            position: "absolute",
            paddingHorizontal: 10,
            height: 30,
            right: 10,
            top: 10,
          }}>
          <Icon fill="#fff" name="heart-outline" width={24} height={24} />
        </View>
      </View>
      <View
        key={id+56145205}
        style={{
          width: "100%",
          height: 50,
          marginTop: 20,
          backgroundColor: status == "Active"?"#8dc641":"#353434",
          flexDirection: "row",
          justifyContent: "space-between",
        }}>
        <View
          style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            marginLeft: 15,
          }}>
            <Text>{status == "Active"?<Icon name="clock-outline" width={18} height={18} fill="#fff" />:""}
            </Text>
          <Text
            style={{
              fontFamily: "Roboto_500Medium",
              color: "#fff",
              marginLeft: 10,
              fontSize: 14,
            }}>
            {status == "Active"?"Open for investing":status}
          </Text>
        </View>
        
        {(() => {
          if (login == 0) {
            return [
        <View
        key={id+522265}
          style={{
            
           
            display: "flex",
            flexDirection: "row",
            position: "absolute",
            right:3,
            top:3,  
            alignItems: "flex-end"
          }}>
            <Button
            onPress={() => {
              onClick(id);
            }}
            style={{  marginBottom: 4, backgroundColor: "#333" }}
            status="success">
            {(evaProps) => (
              <Text style={{ fontFamily: "Roboto_500Medium", color: "white" }}>
               Request Access
              </Text>
            )}
          </Button>
            </View>
            ];
          }
        })()}
        <View></View>
      </View>
      <View   key={id+5614255}
        style={{
          width: "90%",
          height: 220,
          marginTop: 10,
          display: "flex",
          justifyContent: "space-evenly",
          flexDirection: "column",
          alignSelf: "center",
        }}>
        <View
          style={{
            width: "100%",
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            flexDirection: "row",
            height: 50,
          }}>
          <Text style={{ fontFamily: "Roboto_500Medium", color: "#a2a2a2" }}>Minimum Investment</Text>
          <Text style={{ fontFamily: "Roboto_700Bold", fontSize: 16 }}>{min_invest ? currency(min_invest) : "---"}</Text>
        </View>
        <View   key={id+51411165} style={{ width: "100%", borderColor: "#e9e9e9", borderBottomWidth: 1 }} />
        <View
          style={{
            width: "100%",
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            flexDirection: "row",
            marginTop: 5,
            height: 50,
          }}>
          <Text style={{ fontFamily: "Roboto_500Medium", color: "#a2a2a2" }}>Maximum Investment</Text>
          <Text style={{ fontFamily: "Roboto_700Bold", fontSize: 16 }}>{max_invest ? currency(max_invest) : "---"}</Text>
        </View>
        <View style={{ width: "100%", borderColor: "#e9e9e9", borderBottomWidth: 1 }} />
        <View
          key={id+577765}
          style={{
            width: "100%",
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            flexDirection: "row",
            marginTop: 5,
            height: 50,
          }}>
          <Text style={{ fontFamily: "Roboto_500Medium", color: "#a2a2a2" }}>Funding Target</Text>
          <Text style={{ fontFamily: "Roboto_700Bold", fontSize: 16 }}>
            {equity_goal ? currency(equity_goal) : "---"}
          </Text>
        </View>
        <View style={{ width: "100%", borderColor: "#e9e9e9", borderBottomWidth: 1 }} />
        <View  key={id+55565}
          style={{
            width: "100%",
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            flexDirection: "row",
            marginTop: 5,
            height: 50,
          }}>
          <Text style={{ fontFamily: "Roboto_500Medium", color: "#a2a2a2" }}>Investment Due Date</Text>
          <Text style={{ fontFamily: "Roboto_700Bold", fontSize: 16 }}>{closing_date ? currency(closing_date) : "---"}</Text>
        </View>
      </View>
    </TouchableOpacity>
  );
}

export default Card;
