import { useEffect } from 'react';
import AsyncStorage from "@react-native-async-storage/async-storage";

import { useNavigate } from "react-router-native";

const useTokenCheck = () => {
    const navigate = useNavigate();

  useEffect(() => {
    AsyncStorage.getItem("token")
      .then((res) => {
        if (res == null) {
          navigate("/login");
        } 
      })
      .catch(() => {
        navigate("/login");
      });
  }, []); // The empty dependency array ensures this effect runs only once when the component mounts
};

export default useTokenCheck;