import AsyncStorage from "@react-native-async-storage/async-storage";
import axios from "axios";
import { useEffect, useState } from "react";
import { View, Text, Dimensions, ScrollView, TouchableOpacity } from "react-native";
import Swiper from "react-native-web-swiper";
import { useNavigate } from "react-router-native";
import {APIadminsURL} from "@env";
import {
  AreaChart,
  Area,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  ResponsiveContainer,
  PieChart,
  Pie,
  Sector,
  Cell,
  Label,
} from "recharts";
import { Icon } from "react-native-eva-icons";
import { Button, Modal, Card as KittenCard } from "@ui-kitten/components";
import Cardsub from "../../../components/cardsub";
import InvestmentSection from "../../../components/InvestmentSection";
import useTokenCheck  from "../../../components/useTokenCheck";
function Dashboard() {
  useTokenCheck();
  const navigate = useNavigate();
  const [chartParentWidth, setChartParentWidth] = useState(0);
  const [offers, setOffers] = useState([]);
  const [stats, setStats] = useState(null);
  const [statsError, setStatsError] = useState(false);
  const [showInvest, setshowInvest] = useState(true);
  const [linechartdata, setlinechartdata] = useState([]);

  const width = Dimensions.get("window").width;
  const ismobile = width < 768;
  const voidFunction = ()=>{
    //alert("ok");
    setshowInvest(false)
  }
  const linechartdata5 = [
    {
      name: "Jan",
      Total_investment:400, Expected_Value: 2400, amt: 1
    },
    {
      name: "Feb",
      Total_investment:500, Expected_Value: 3400, amt: 1
    },
    {
      name: "Mar",
    },
    {
      name: "Apr",
    },
    {
      name: "May",
    },
    {
      name: "Jun",
    },
    {
      name: "Jul",
    },
    {
      name: "Aug",
    },
    {
      name: "Sep",
    },
    {
      name: "Oct",
    },
    {
      name: "Nov",
    },
    {
      name: "Dec",
    },
  ];
  const COLORS = ["#68a729", "#a4ca7f", "#86b954", "#77b03e", "#95c169"];
  

  useEffect(() => {
    AsyncStorage.getItem("token")
      .then((res) => {
        if (res !== null) {
          axios
            .get(`${APIadminsURL}/public/api/myoffers`, {
              headers: { Authorization: `Bearer ${res}` },
            })
            .then((result) => {
              if (result.data.length > 0) {
                if(result.data.length > 12){
                  var myoffers = result.data.slice(0, 12);
                  setOffers(myoffers);

                }else{
                setOffers(result.data);
              }
              }
            })
            .catch((res) => {
                //navigate("/login");
              
            });
          // {
          //   "number of subscriptions": 0,
          //   "number of contributions": 0,
          //   "number of distributions": 0,
          //   "total investment": 0,
          //   "current investment": 0,
          //   "total distribution": 0,
          //   "ROI": 0,
          //   "portfolio": []
          // }
          axios
            .get(`${APIadminsURL}/public/api/stats`, {
              headers: { Authorization: `Bearer ${res}` },
            })
            .then((result) => {
              setStats(result.data);
              setlinechartdata(result.data.contributionStats);
            })
            .catch((res) => {
              if (res.response.status === 401) {
                navigate("/login");
              }
              setStatsError(true);
              setStats({ portfolio: [] });
            });
        } else {
          navigate("/login");
        }
      })
      .catch(() => {
      });
  }, []);
  if (!stats) return <Text>Loading</Text>;
  return (
    <ScrollView style={{ backgroundColor: "#f3f3f3" }}>
      <View
        //!first row of cards
        style={{
          marginTop: 100,
          width: "100%",
          flexDirection: "row",
          flexWrap: "wrap",
          justifyContent: "space-around",
        }}>
        <SmallCard
          id={0}
          icon="file-text-outline"
          title="Total investment"
          value={stats ? currency(stats["total investment"]) : "0"}
        />
        <SmallCard
          id={1}
          icon="home-outline"
          title="Current investment value"
          value={stats ? currency(stats["current investment"]) : "0"}
        />
        <SmallCard
          id={2}
          icon="file-text"
          title="Total Distribution"
          value={stats ? currency(stats["total distribution"]) : "0"}
        />
        <SmallCard
          id={3}
          icon="trending-up-outline"
          title="Return on Investment"
          value={stats ? currency(stats["ROI"]) : "0"}
        />
      </View>
      <View
        //!second row of cards and swiper
        style={{
          width: "100%",
          flexDirection: "row",
          flexWrap: "wrap",
          justifyContent: "space-around",
        }}>
        <View
          style={{
            flex: 1,
            flexDirection: "row",
            flexWrap: "wrap",
            alignSelf: "flex-start",
            justifyContent: "space-around",
            paddingHorizontal: 10,
          }}>
          <View
            style={{
              padding: 10,
              height: 280,
              backgroundColor: "#fff",
              borderRadius: 10,
              margin: 10,
              alignSelf: "flex-start",
              flex: 1,
              minWidth: ismobile ? "90%" : 350,
            }}>
            <Text
              style={{
                alignSelf: "flex-start",
                marginLeft: 10,
                marginTop: 10,
                marginBottom: 10,
                fontFamily: "Roboto_700Bold",
                fontSize: 16,
              }}>
              Portfolio Summary
            </Text>
            <ResponsiveContainer width="100%" height={200}>
              <AreaChart
                width={500}
                height={400}
                data={linechartdata}
                style={{ fontFamily: "Roboto_400Regular", fontSize: 12 }}
                margin={{
                  top: 10,
                  right: 30,
                  left: 0,
                  bottom: 0,
                }}>
                <CartesianGrid strokeDasharray="3 3" />
                <XAxis dataKey="name" />
                <YAxis />
                <Tooltip />
                <Area type="monotone" dataKey="Total investment" stroke="#68a729" fill="#68a72933" />
                <Area type="monotone" dataKey="Expected Value"  stroke="#8dc641" fill="#8dc64133" />
              </AreaChart>
            </ResponsiveContainer>
            
          </View>

          <View
            onLayout={({ nativeEvent }) => setChartParentWidth(nativeEvent.layout.width)}
            style={{
              padding: 10,
              height: 280,
              backgroundColor: "#fff",
              borderRadius: 10,
              margin: 10,
              flex: 1,
              minWidth: ismobile ? "90%" : 350,
            }}>
            <Text
              style={{
                alignSelf: "flex-start",
                marginLeft: 10,
                marginTop: 10,
                marginBottom: 10,
                fontFamily: "Roboto_700Bold",
                fontSize: 16,
              }}>
              Portfolio allocations
            </Text>
            <View style={{ flexDirection: "row" }}>
              <ResponsiveContainer
                width={stats?.portfolio.length > 0 ? "50%" : "100%"}
                height={200}>
                {/* return to 50% when data available */}
                <PieChart
                  width="100%"
                  height={200}
                  style={{ fontFamily: "Roboto_400Regular", fontSize: 12 }}>
                  <Pie
                    data={
                      stats?.portfolio.length > 0
                        ? stats.portfolio
                        : [{ name: "dead", percentage: 100 }]
                    }
                    innerRadius={ismobile ? "80%" : 60}
                    outerRadius={ismobile ? "100%" : 80}
                    fill="#8884d8"
                    paddingAngle={0}
                    dataKey="percentage">
                    <Label
                      fontSize={32}
                      style={{ fontFamily: "Roboto_500Medium" }}
                      value={stats?.portfolio.length ?? 0}
                      position="center"
                    />
                    {stats.portfolio.length === 0 ? (
                      <Cell key="dead-cell" fill={COLORS[0]} />
                    ) : (
                      stats.portfolio?.map((entry, index) => (
                        <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
                      ))
                    )}
                  </Pie>
                </PieChart>
              </ResponsiveContainer>
              {/* remove this comment down below when data available */}
              <View
                style={{
                  alignItems: "flex-start",
                  justifyContent: "space-evenly",
                  flexDirection: "column",
                  marginLeft: 20,
                }}>
                {stats.portfolio?.map((item, index) => {
                  return (
                    <View key={index}  styel={{ flexDirection: "column", justifyContent: "flex-start" }}>
                      <View
                        style={{
                          flexDirection: "row",
                          justifyContent: "flex-start",
                          alignItems: "center",
                        }}>
                        <View
                          style={{
                            width: 10,
                            height: 10,
                            marginRight: 10,
                            borderRadius: 20,
                            backgroundColor: COLORS[index],
                          }}
                        />
                        <Text>{item.investment}</Text>
                      </View>
                      <Text
                        style={{
                          fontFamily: "Roboto_400Regular",
                          fontSize: 12,
                          color: "#9c9c9c",
                          marginLeft: 20,
                          alignSelf: "flex-end",
                        }}>
                        {currency(item.contributions)}
                      </Text>
                    </View>
                  );
                })}
              </View>
            </View>
          </View>
          {showInvest && 
          <View
            onLayout={({ nativeEvent }) => setChartParentWidth(nativeEvent.layout.width)}
            style={{
              padding: 10,
              height: "auto",
              backgroundColor: "#fff",
              borderRadius: 10,
              margin: 10,
              marginBottom: 30,
              flex: 1,
              minWidth: ismobile ? "90%" : 650,
            }}>
              <Text
              style={{
                alignSelf: "flex-start",
                marginLeft: 10,
                marginTop: 10,
                marginBottom: 5,
                fontFamily: "Roboto_700Bold",
                fontSize: 16,
              }}>
              Investments
            </Text>
            <InvestmentSection voidFunction={voidFunction}/>
          </View>}
        </View>
        
        <View
          style={{
            width: ismobile ? "100%" : "25%",
            minWidth: ismobile ? null : 400,
            height: 630,
            margin: 10,
          }}>
          {offers.length > 0 && (
            <Swiper
              containerStyle={{ flex: 1 }}
              controlsProps={{
                dotActiveStyle: { backgroundColor: "#8DC641" },
                nextTitleStyle: { color: "#8DC641" },
                prevTitleStyle: { color: "#8DC641" },
              }}
              timeout={5}
              loop>
              {offers ? (
                offers.map((inv, key) => {
                  return (
                    <Cardsub 
                      offerings_mode
                      maxwidth={500}
                      height={600}
                      id={inv.id}
                      name={inv.name}
                      status={inv.status}
                      equity_goal={inv.display_raise == 0 ? null : inv.equity_goal}
                      min_invest={inv.min_invest}
                      max_invest={inv.max_invest}
                      closing_date={inv.display_close == 0 ? null : inv.closing_date}
                      photo={inv.cover}
                      city={inv.city}
                      country={inv.country}
                      street_name={inv.street_name}
                      tags={inv.tags}
                      sponsor={inv.sponsors}
                      ROI={inv.ROI}
                      hold_time={inv.hold_time}
                      key={key}
                    />
                  );
                })
              ) : (
                <View
                  style={{
                    width: "100%",
                    height: "100%",
                    justifyContent: "center",
                    alignItems: "center",
                    backgroundColor: "#f0f",
                    borderRadius: 10,
                  }}>
                  <Text>No offers available</Text>
                </View>
              )}
            </Swiper>
          )}
        </View>
      </View>
    </ScrollView>
  );
}

export default Dashboard;

function SmallCard({ title, value, icon, id }) {
  const [showinfomodal, setShowinfomodal] = useState(false);
  const width = Dimensions.get("window").width;
  const ismobile = width < 768;
  const information = [
    "Total investments are the amount of money invested by you in active transactions. This value does not include committed capital for investments where payments have not yet been completed or processed.",

    "Current investment value is the real-time value of your investment. It is calculated on a prorate basis to offer you the possibility of tracking the progress of your investments.",

    "Total amount that you received from S7 equity on transactions that are still active including the reinvested amounts.",

    "Return on investment (ROI) indicates the returns you will receive in respect of the committed capital you contributed. The higher the ratio, the greater the benefit earned.",
  ];
  return (
    <>
      <View
        style={{
          width: ismobile ? "90%" : "23%",
          backgroundColor: "#fff",
          height: 150,
          borderRadius: 10,
          margin: 10,
          flexDirection: "row",
          alignItems: "center",
          alignSelf: "center",
          minWidth: 250,
        }}>
        <TouchableOpacity
          onPress={() => setShowinfomodal(true)}
          style={{
            width: 25,
            height: 25,
            borderRadius: 30,
            position: "absolute",
            top: 10,
            right: 10,
            justifyContent: "center",
            alignItems: "center",
          }}>
          <Icon name="question-mark-circle" width={20} height={20} fill="#aaa" />
        </TouchableOpacity>
        <View
          style={{
            justifyContent: "center",
            alignItems: "center",
            flex: 1,
            height: "100%",
          }}>
          <View
            style={{
              width: 60,
              height: 60,
              justifyContent: "center",
              alignItems: "center",
              backgroundColor: "#8dc641",
              borderRadius: 5,
            }}>
            <Icon name={icon} width={40} height={40} fill="#fff" />
          </View>
        </View>
        <View
          style={{
            alignItems: "center",
            flex: 2,
            height: "50%",
            flexDirection: "column",
            justifyContent: "space-evenly",
          }}>
          <Text
            style={{
              alignSelf: "flex-start",
              marginLeft: 10,
              fontFamily: " Roboto_500Medium,",
              fontSize: 13,
              color: "#969696",
            }}>
            {title}
          </Text>
          <Text
            style={{
              alignSelf: "flex-start",
              marginLeft: 10,
              fontFamily: "Roboto_400Regular",
              fontSize: 24,
            }}>
            {value}
          </Text>
        </View>
      </View>
      <Modal
        style={{ width: ismobile ? "80%" : 300, maxWidth: 500,}}
        visible={showinfomodal}
        backdropStyle={{ backgroundColor: "rgba(0, 0, 0, 0.5)" }}
        onBackdropPress={() => setShowinfomodal(false)}
    >
        
        <KittenCard
          style={{
            maxWidth: 500,
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            flexDirection: "column",
            position: "fixed",
            left: ismobile ? "0%" : "50%",
            transform: ismobile ? "translate(-0%,-50%)" : "translate(-50%,-50%)",
            top: "50%",
          }}>
             <View style={{flexDirection:'row', flexWrap:'wrap'}}>
    <Text
            style={{
              fontFamily: "Roboto_700Bold",
              fontSize: 18,
              color: "#464646",
              marginVertical: 10,
              flex:0.5
            }}>
            {title}
          </Text><View style={{
              
              flex:0.5
            }}>
                  <TouchableOpacity onPress={() => setShowinfomodal(false)} size={"small"} appearance="ghost" style={{width:10,alignSelf: 'flex-end'}}> <Icon name="close-outline" width={20} height={20} style={{top:0}} fill="#aaa" />
</TouchableOpacity>
                  </View>
    </View>
           
          
          
          <Text
            style={{
              fontFamily: "Roboto_400Regular",
              fontSize: 16,
              color: "#3d3d3d",
              marginVertical: 10,
              textAlign: "justify",
            }}>
            {information[id]}
          </Text>
          <View
            style={{
              marginTop: 20,
              margin: 5,
              flexDirection: "row",
              justifyContent: "space-around",
            }}>
            <Button
              status="success"
              onPress={() => {
                setShowinfomodal(false);
              }}>
              Ok
            </Button>
          </View>
        </KittenCard>
      </Modal>
    </>
  );
}
function currency(num) {
  if (num) {
    let x = num.toLocaleString("en-US", {
      style: "currency",
      currency: "USD",
      maximumFractionDigits: 0,
    });
    if (x.substring(0, 1) === "$") {
      x = x.substring(1);
    }
    return x;
  } else {
    return num;
  }
}
