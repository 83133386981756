import AsyncStorage from "@react-native-async-storage/async-storage";
import { Button, Text, CheckBox, Card, Modal, Spinner } from "@ui-kitten/components";
import axios from "axios";
import base64 from "base-64";
import { useEffect, useRef, useState } from "react";
import { useForm, Controller } from "react-hook-form";
import { Image, Linking, ScrollView, StyleSheet, TextInput, View } from "react-native";
import { Modalize } from "react-native-modalize";
import RenderHtml from "react-native-render-html";
import { useToast } from "react-native-toast-notifications";
import { useNavigate, useSearchParams } from "react-router-native";
import {APIadminsURL} from "@env";
import logo from "../../../assets/s7logo_crop.png";

function SignupPage() {
  const [searchParams] = useSearchParams();
  const [redirect] = useState(searchParams.get("redirect_uri"));
  const [invalid, setInvalid] = useState(false);
  const [checked, setChecked] = useState(false);
  const [visible, setVisible] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [email, setemail] = useState();
  const [id, setId] = useState();

  const [invid, setinv] = useState();
  const navigate = useNavigate();
  const toast = useToast();
  const [thisSource, setSource] = useState("<p></p>");
  const modalizeRef = useRef(null);
  const [WelcomeMessage, setWelcomeMessage] = useState("");
  
  //valid redirect
  //eyJlbWFpbCI6InRlc3RAdGVzdC5jb20iLCIgaW52ZXN0bWVudF9pZCI6IjEyMzQ1Njc4OSJ9
  //check email: ${APIadminsURL}/public/api/check-user
  useEffect(() => {
    axios
      .get(`${APIadminsURL}/api/welcomeMessage`)
      .then((res) => {
        setWelcomeMessage(res.data.WelcomeMessage);
      })
      .catch(() => {});
  }, []);
  const storeData = async (value) => {
    try {
      await AsyncStorage.setItem("token", value);
    } catch (e) {
      console.log("exception caught:", e);
    }
  };
  function openTosModal(e) {
    modalizeRef.current?.open();
    if (e == "termsOfUse") {
      axios
        .get(`${APIadminsURL}/api/TermsOfUse`)
        .then((res) => {
          setSource(res.data.agreement);
        })
        .catch(() => {});
    } else {
      axios
        .get(`${APIadminsURL}/api/PrivacyPolicy`)
        .then((res) => {
          setSource(res.data.agreement);
        })
        .catch(() => {});
    }
  }
  function closeTosModal() {
    modalizeRef.current?.close();
  }

  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm({
    defaultValues: {
      password: "",
      password_confirmation: "",
    },
  });
  const onSubmit = (data) => {
    data.email = email;
    data.id = id;
    if (!checked) {
      toast.show("please accept the terms of service to continue", {
        type: "warning",
        placement: "bottom",
      });
    } else if (data.password === data.password_confirmation) {
      setIsLoading(true);
      axios
        .post(`${APIadminsURL}/api/auth/register`, data)
        .then((res) => {
          //setIsLoading(false);
          //setVisible(true);
          axios
            .post(`${APIadminsURL}/api/auth/login`, {
              email,
              password: data.password,
            })
            .then((result) => {
              storeData(result.data.access_token);
              if(invid){
              navigate("/investments/" + invid);
              }
              else
              {
                navigate("/dashboard/");
              }
            });
        })
        .catch((e) => {
          console.log(e);
          setIsLoading(false);
          alert(e);
         /*  toast.show(e, {
            type: "danger",
            placement: "bottom",
          }); */
        });
    } else {
      toast.show("please verify password fields", {
        type: "danger",
        placement: "bottom",
      });
    }
  };

  useEffect(() => {
    try {
      const res = base64.decode(redirect);
      const ret = res
        .replace("a:3:","")
        .replace("a:2:", "")
        .replace("a:1:", "")
        .replace("s:5:", "")
        .replace(";s:13:", ",")
        .replace(";i", "")
        .replace(";", "")
        .replace(/[a-z][:][0-9][0-9]/, "")
        .replace(";", "")
        .replace("s:2:", ",")
        .replace(";", "")
        .replace('"id"i:','"id":')
        .replace(";", "");

         console.log(res);
        console.log(ret);
        console.log(JSON.parse(ret).email);
        console.log(JSON.parse(ret).id); 

        setemail(JSON.parse(ret).email);
        setId(JSON.parse(ret).id);
      axios
        .get(
          `${APIadminsURL}/public/api/check-user?email=` + JSON.parse(ret).email
        )
        .then((res) => {
          if (res.data.user) {
            navigate(
              "/login?redirect_uri=" +
                base64.encode(
                  `{"email":"${JSON.parse(ret).email}", "investment_id":"${
                    JSON.parse(ret).investment_id
                  }", "exist":true}`
                )
            );
          }
        });
      setinv(JSON.parse(ret).investment_id);
      // eslint-disable-next-line no-unused-vars
    } catch (e) {
      console.log(e);
      setInvalid(true);
    }
  }, []);

  const LoadingIndicator = (props) => (
    <View style={{ justifyContent: "center", alignItems: "center", marginRight: 10 }}>
      <Spinner status="basic" size="small" />
    </View>
  );

  if (invalid) {
    return (
      <View style={styles.container}>
        <Text>error</Text>
      </View>
    );
  }

  return (
    <>
      <View style={styles.container}>
        <View
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
            width: "80%",
            backgroundColor: "#fff",
            maxWidth: 500,
            paddingVertical: 30,
            borderRadius: 20,
            boxShadow: "1px 2px 9px #ddd",
            elevation: 10,
            shadowColor: "#555",
          }}>
          <Image source={logo} style={{ width: "100%", height: 100, resizeMode: "contain" }} />
          <View style={{ width: "80%", marginTop: 20 }}>
            <RenderHtml source={{ html: `${WelcomeMessage}` }} />
          </View>
          <View style={{ width: "80%", marginTop: 20 }}>
            <Text style={{ fontFamily: "Roboto_400Regular", marginBottom: 4, marginTop: 10 }}>
              Password:
            </Text>
            <Controller
              control={control}
              rules={{
                required: true,
                minLength: 6,
              }}
              name="password"
              render={({ field: { onChange, onBlur, value } }) => (
                <TextInput
                  secureTextEntry
                  value={value}
                  onChangeText={onChange}
                  onBlur={onBlur}
                  style={{
                    marginBottom: 5,
                    backgroundColor: "#f7f9fc",
                    borderColor: "#e4e9f2",
                    borderWidth: 1,
                    height: 40,
                    borderRadius: 4,
                    padding: 7,
                    width: "100%",
                  }}
                />
              )}
            />
            {errors.password?.type === "required" ? (
              <Text style={styles.inputerror}>This field is required</Text>
            ) : errors.password?.type === "minLength" ? (
              <Text style={styles.inputerror}>The Password must be atleast 6 characters long</Text>
            ) : null}
            <Text style={{ fontFamily: "Roboto_400Regular", marginBottom: 4, marginTop: 10 }}>
              Confirm Password:
            </Text>
            <Controller
              control={control}
              rules={{
                required: true,
                minLength: 6,
                value: control._formValues.password,
              }}
              name="password_confirmation"
              render={({ field: { onChange, onBlur, value } }) => (
                <TextInput
                  secureTextEntry
                  value={value}
                  onChangeText={onChange}
                  onBlur={onBlur}
                  style={{
                    marginBottom: 5,
                    backgroundColor: "#f7f9fc",
                    borderColor: "#e4e9f2",
                    borderWidth: 1,
                    height: 40,
                    borderRadius: 4,
                    padding: 7,
                    width: "100%",
                  }}
                />
              )}
            />
            {errors.password_confirmation?.type === "required" ? (
              <Text style={styles.inputerror}>This field is required</Text>
            ) : errors.password?.type === "minLength" ? (
              <Text style={styles.inputerror}>The Password must be atleast 6 characters long</Text>
            ) : null}
            <View
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "flex-start",
                alignItems: "center",
              }}>
              <CheckBox
                style={{ marginBottom: 16 }}
                checked={checked}
                onChange={() => {
                  setChecked(!checked);
                }}
                status="success">
                {(evaProps) => (
                  <Text style={{ fontFamily: "Roboto_400Regular", marginLeft: 8 }}>
                    I agree to the{" "}
                  </Text>
                )}
              </CheckBox>
              <Text
                onPress={() => {
                  Linking.openURL("/#/tos");
                  //navigate("/tos");
                  //openTosModal("termsOfUse");
                }}
                status="success"
                style={{ fontFamily: "Roboto_400Regular", marginBottom: 16 }}>
                terms of use{" "}
              </Text>
              <Text style={{ fontFamily: "Roboto_400Regular", marginBottom: 16 }}>and </Text>
              <Text
                onPress={() => {
                  //openTosModal("PrivacyPolicy");
                  Linking.openURL("/#/privacypolicy");
                  //navigate("/privacypolicy");
                }}
                status="success"
                style={{ fontFamily: "Roboto_400Regular", marginBottom: 16 }}>
                privacy policy
              </Text>
            </View>
          </View>
          <Button
            disable={isLoading}
            accessoryLeft={isLoading ? <LoadingIndicator /> : null}
            onPress={handleSubmit(onSubmit)}
            style={{ width: 150, marginTop: 12, marginBottom: 1 }}
            status="success">
            {(evaProps) => (
              <Text style={{ fontFamily: "Roboto_500Medium", color: "white" }}>SIGN UP</Text>
            )}
          </Button>
        </View>
      </View>

      <Modalize modalStyle={{ width: "95%", maxWidth: 500, alignSelf: "center" }} ref={modalizeRef}>
        <View style={{ width: "100%", height: 600 }}>
          <ScrollView
            style={{
              paddingVertical: 10,
              paddingHorizontal: 10,
              borderColor: "#eee",
              borderWidth: 1,
              margin: 20,
            }}>
            <View style={{ paddingHorizontal: 5 }}>
              <RenderHtml source={{ html: `${thisSource}` }} />
            </View>
          </ScrollView>
        </View>
        <Button
          style={{ width: "80%", alignSelf: "center", marginBottom: 20 }}
          status="success"
          onPress={() => closeTosModal()}>
          DISMISS
        </Button>
      </Modalize>
      <Modal
        style={{ width: "90%", maxWidth: 400 }}
        visible={visible}
        backdropStyle={styles.backdrop}
        onBackdropPress={() => null}>
        <Card
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            flexDirection: "column",
          }}>
          <Text
            style={{
              fontFamily: "Roboto_700Bold",
              fontSize: 22,
              marginBottom: 10,
              marginTop: 10,
            }}>
            Welcome to s7equity
          </Text>
          <Text
            style={{
              fontFamily: "Roboto_400Regular",
              fontSize: 16,
              marginBottom: 20,
              marginTop: 10,
            }}>
            You're account has been successfully created, Proceed to the login page to access your
            account
          </Text>
          <Button status="success" onPress={() => navigate("/login?redirect_id=1")}>
            Sign In to your account
          </Button>
        </Card>
      </Modal>
    </>
  );
}

export default SignupPage;

const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: "#f3f4f6",
    alignItems: "center",
    justifyContent: "center",
  },
  backdrop: {
    backgroundColor: "rgba(0, 0, 0, 0.5)",
  },
  inputerror: {
    fontFamily: "Roboto_400Regular",
    fontSize: 12,
    color: "#f00",
    marginBottom: 5,
  },
});
