import AsyncStorage from "@react-native-async-storage/async-storage";
import axios from "axios";
import { useEffect, useState } from "react";
import { Dimensions, Text, TextInput, View } from "react-native";
import { Icon } from "react-native-eva-icons";
import { color } from "react-native-reanimated";
import { useNavigate, useParams } from "react-router-dom";

//import Card from "../../../components/card";
import Cardsub from "../../../components/cardsub";
//import CompactCard from "../../../components/compactcard";
import CardSubscription from "../../../components/cardsubscription";
import SubStep1 from "../step1";
import SubStep2 from "../step2";
import SubStep3 from "../step3";
import SubStep4 from "../step4";
import {APIadminsURL} from "@env";
import useTokenCheck  from "../../../components/useTokenCheck";

function SubStepper(props) {
  useTokenCheck();
  const width = Dimensions.get("window").width;
  const { investment } = useParams();
  const [investmentbody, setInvestmentbody] = useState("");
 /*  const ismobile = width < 768; */
 const navigate = useNavigate();
 const ismobile = width < 1150;
  const [Msg, setMsg] = useState("");
  const { id } = useParams();
  const [step, setStep] = useState(0);
  useEffect(() => {
    AsyncStorage.getItem("token").then((token) => {
      if (token !== null) {
        axios
          .get(`${APIadminsURL}/public/api/subscription/` + id, {
            headers: { Authorization: `Bearer ${token}` },
          })
          .then((resSub) => {
            if (resSub.data.step) {
              setStep(resSub.data.step);
            }
            if (resSub.data.approvement == "Request modification") {
              setMsg(resSub.data.reason);
            }
            //res.data.forEach(myFunction);commitment
          });
      }
    });
  }, []);
  useEffect(() => {
    AsyncStorage.getItem("token").then((res) => {
      axios
        .get(`${APIadminsURL}/public/api/investment/` + investment, {
          headers: { Authorization: `Bearer ${res}` },
        })
        .then((investmentx) => {
          setInvestmentbody(investmentx?.data.investment);
        }).catch((res) => {
          if (res.response.status === 401) {
            navigate("/login");
          }
        });;
    });
  }, []);
  const stepsarray = [
    <SubStep1
      nextstep={() => {
        setStep(1);
      }}
    />,
    <SubStep2
      nextstep={() => {
        setStep(2);
      }}
      prevstep={() => {
        setStep(0);
      }}
    />,
    <SubStep3
      nextstep={() => {
        setStep(3);
      }}
      prevstep={() => {
        setStep(1);
      }}
    />,
    <SubStep4
      nextstep={() => {
        setStep(3);
      }}
      prevstep={() => {
        setStep(2);
      }}
    />,
    <></>,
  ];

  return (
    <View style={{ backgroundColor: "#f3f3f3", marginTop: ismobile ? 10 : 30 }}>
      <View
        style={{
          height: 50,
          width: "100%",
          paddingHorizontal: "6%",
          marginBottom: 30,
          alignSelf: "center",
          alignItems: "center",
          justifyContent: "center",
          marginTop: 100,
          flexDirection: "row",
        }}>
        <Hstep key={111} mykey={5454} icon="person-done-outline" name="Basic Details" state={step === 0 ? 1 : 2} isfirst />
        <Hstep key={1111212} mykey={545415} icon="info-outline" name="Statements" state={step < 1 ? 0 : step === 1 ? 1 : 2} />
        <Hstep key={11001} mykey={545004}
          icon="file-text-outline"
          name="Documents"
          state={step < 2 ? 0 : step === 2 ? 1 : 2}
        />
        <Hstep key={117511} mykey={5475154} icon="flag-outline" name="Summary" state={step < 3 ? 0 : step === 3 ? 1 : 2} />
      </View>
      <View
        style={{
          flexDirection: ismobile ? "column" : "row",
          width: "95%",
          alignSelf: "center",
          flexWrap: "wrap",
          justifyContent: "center",
          marginTop: ismobile ? 10 : 20,
        }}>
        <View style={{ width: ismobile ? "100%" : 450, alignItems: "center", marginBottom:ismobile?10:200 }}>
          {ismobile ? (
            <CardSubscription
            key={121122}
         mykey={5477754}
              id={investmentbody?.id}
              name={investmentbody?.name}
              status={investmentbody?.status}
              equity_goal={investmentbody?.equity_goal}
              min_invest={investmentbody?.min_invest}
              max_invest={investmentbody?.max_invest}
              closing_date={investmentbody?.closing_date}
              city={investmentbody?.city}
              country={investmentbody?.country}
              street_name={investmentbody?.street_name}
              tags={investmentbody?.tags}
              sponsor={investmentbody.sponsors}
              ROI={investmentbody.ROI}
              hold_time={investmentbody.hold_time}
            />
          ) : (
            <Cardsub
            key={1117099} 
            mykey={5454151}
              height={500}
              id={investmentbody?.id}
              name={investmentbody?.name}
              status={investmentbody.status}
              equity_goal={investmentbody.equity_goal}
              min_invest={investmentbody.min_invest}
              max_invest={investmentbody.max_invest}
              closing_date={investmentbody.closing_date}
              photo={investmentbody.cover}
              city={investmentbody.city}
              country={investmentbody.country}
              street_name={investmentbody.street_name}
              tags={investmentbody.tags}
              sponsor={investmentbody.sponsors}
              ROI={investmentbody.ROI}
              hold_time={investmentbody.hold_time}
            />
          )}
        </View>
        <View style={{ flex: 1 }}>
          {(() => {
            if (Msg) {
              return (
                <View
                  style={{
                    backgroundColor: "#969696",
                    borderRadius: 15,
                    padding: 25,
                    zIndex: 9,
                    marginBottom: 10,
                  }}>
                  <Text>{" "}</Text>
                  <Icon
                    name="alert-triangle-outline"
                    fill="#fff"
                    width={32}
                    height={32}
                    style={{ paddingBottom: 10 }}
                  />
                  <Text style={{ fontFamily: "Roboto_700Bold", color: "#fff" }}>
                    Requested modification:
                  </Text><Text>{" "}</Text>
                  <Text style={{ fontFamily: "Roboto_400Regular", color: "#fff", marginTop: 10 }}>
                    {Msg}
                  </Text>
                </View>
              );
            }
          })()}
          {stepsarray[step]}
        </View>
      </View>
      {/* {stepsarray[step]} */}
    </View>
  );
}

export default SubStepper;

function Hstep({ name, state, isfirst, icon }) {
  let bgcolor = "#000";
  let txtcolor = "#000";
  let linecolor = "#000";
  const width = Dimensions.get("window").width;
  const ismobile = width < 900;
  if (state === 0) {
    bgcolor = "#e9e9e9";
    linecolor = "#e9e9e9";
    txtcolor = "#000";
  } else if (state === 1) {
    bgcolor = "#e3ecd6";
    linecolor = "#8dc641";
    txtcolor = "#000";
  } else {
    bgcolor = "#8dc641";
    linecolor = "#8dc641";
    txtcolor = "#fff";
  }
  return (
    <>
      {!isfirst && <View style={{ width: "15%", height: 2, backgroundColor: linecolor }} />}
      <View
        style={{
          backgroundColor: bgcolor,
          height: 50,
          width: ismobile ? 50 : 190,
          flexDirection: "row",
          justifyContent: "center",
          alignItems: "center",
          borderRadius: ismobile ? 50 : 10,
        }}>
        <Icon name={icon} fill={txtcolor} width={18} height={18} />
        {ismobile ? (
          <></>
        ) : (
          <Text
            style={{
              color: txtcolor,
              fontFamily: "Roboto_500Medium",
              fontSize: 18,
              marginLeft: 5,
            }}>
            {name}
          </Text>
        )}
      </View>
    </>
  );
}
