import { Text, View } from "react-native";

function DocumentsView(props) {
  return (
    <View style={{}}>
      <Text
        style={{
          alignSelf: "center",
          fontSize: 20,
          color: "#767676",
          margin: 10,
          fontFamily: "Roboto_500Medium",
        }}>
        {props.title}
      </Text>
      <View
        style={{
          width: "100%",
          alignItems: "center",
          justifyContent: "center",
        }}>
        {props.children}
      </View>
    </View>
  );
}

export default DocumentsView;
