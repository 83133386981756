import { Dimensions, Text, TouchableOpacity, View } from "react-native";
import { Icon } from "react-native-eva-icons";
import { useNavigate } from "react-router-native";
function CompactCard({
  id,
  name,
  equity_goal,
  min_invest,
  max_invest,
  closing_date,
  street_name,
  city,
  country,
}) {
  const navigate = useNavigate();
  function currency(num) {
    if (num)
      return num.toLocaleString("en-US", {
        style: "currency",
        currency: "USD",
        maximumFractionDigits: 0,
      });
  }
  const width = Dimensions.get("window").width;
  const ismobile = width < 768;
  return (
    <TouchableOpacity
      onPress={() => {
        navigate("/investments/" + id);
      }}
      style={{
        width: "100%",
        alignSelf: "center",
        display: "flex",
        flexDirection: "column",
        borderRadius: 15,
        backgroundColor: "#f9f9f9",
        paddingBottom: 15,
        marginBottom: 10,
      }}>
      <View
        style={{
          width: "100%",
          justifyContent: "center",
          flexDirection: "row",
        }}>
        <View
          style={{
            height: 70,
            paddingHorizontal: 15,
            paddingVertical: 10,
            backgroundColor: "#000",
            width: "100%",
            borderTopRightRadius: 15,
            borderTopLeftRadius: 15,
          }}>
          <Text
            style={{
              fontFamily: "Roboto_700Bold",
              color: "#fff",
              fontSize: 26,
            }}>
            {name}
          </Text>

          <View
            style={{
              flexDirection: "row",
              alignItems: "center",
            }}>
            <Icon name="pin-outline" width={20} height={20} fill="#fff" />
            <Text style={{ fontFamily: "Roboto_500Medium", color: "#fff", marginLeft: 5 }}>
              {street_name ? street_name + ", " : ""}
            
              {city ? city + ", " : ""}
             
              {country ? country  : ""}
            </Text>
          </View>
        </View>
      </View>
      <View
        style={{
          width: "100%",
          height: 50,
          backgroundColor: "#8dc641",
          flexDirection: "row",
          justifyContent: "space-between",
        }}>
        <View
          style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            marginLeft: 15,
          }}>
          <Icon name="clock-outline" width={18} height={18} fill="#fff" />
          <Text
            style={{
              fontFamily: "Roboto_500Medium",
              color: "#fff",
              marginLeft: 10,
              fontSize: 14,
            }}>
            Open for investing
          </Text>
        </View>
      </View>
      <View
        style={{
          width: "90%",
          height: 110,
          marginTop: 10,
          display: "flex",
          justifyContent: "space-evenly",
          flexDirection: "column",
          alignSelf: "center",
        }}>
        <View
          style={{
            width: "100%",
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            flexDirection: "row",
            height: 25,
          }}>
          <Text style={{ fontFamily: "Roboto_500Medium", color: "#a2a2a2" }}>Minimum Investment</Text>
          <Text style={{ fontFamily: "Roboto_700Bold", fontSize: 16 }}>{currency(min_invest)}</Text>
        </View>
        <View style={{ width: "100%", borderColor: "#e9e9e9", borderBottomWidth: 1 }} />
        <View
          style={{
            width: "100%",
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            flexDirection: "row",
            marginTop: 5,
            height: 25,
          }}>
          <Text style={{ fontFamily: "Roboto_500Medium", color: "#a2a2a2" }}>Maximum Investment</Text>
          <Text style={{ fontFamily: "Roboto_700Bold", fontSize: 16 }}>{currency(max_invest)}</Text>
        </View>
        <View style={{ width: "100%", borderColor: "#e9e9e9", borderBottomWidth: 1 }} />
        <View
          style={{
            width: "100%",
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            flexDirection: "row",
            marginTop: 5,
            height: 25,
          }}>
          <Text style={{ fontFamily: "Roboto_500Medium", color: "#a2a2a2" }}>Funding Target</Text>
          <Text style={{ fontFamily: "Roboto_700Bold", fontSize: 16 }}>
            {currency(equity_goal)}
          </Text>
        </View>
        <View style={{ width: "100%", borderColor: "#e9e9e9", borderBottomWidth: 1 }} />
        <View
          style={{
            width: "100%",
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            flexDirection: "row",
            marginTop: 5,
            height: 25,
          }}>
          <Text style={{ fontFamily: "Roboto_500Medium", color: "#a2a2a2" }}>Investment Due Date</Text>
          <Text style={{ fontFamily: "Roboto_700Bold", fontSize: 16 }}>{closing_date}</Text>
        </View>
      </View>
    </TouchableOpacity>
  );
}

export default CompactCard;
