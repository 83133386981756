import AsyncStorage from "@react-native-async-storage/async-storage";
import { Button, Card, Modal } from "@ui-kitten/components";
import axios from "axios";
import * as DocumentPicker from "expo-document-picker";
import HelloSign from "hellosign-embedded";
import { useEffect, useState } from "react";
import { useDropzone } from "react-dropzone";
import { Text, TouchableOpacity, View, Dimensions } from "react-native";
import { Icon } from "react-native-eva-icons";
import RenderHTML from "react-native-render-html";
import { useToast } from "react-native-toast-notifications";
import { useParams } from "react-router-dom";
import {APIadminsURL} from "@env";
import DocListItem from "../../../components/documents/doclistitem";
import LoadingComp from "../../../components/loading";

function SubStep3({ prevstep, nextstep }) {
  const { acceptedFiles, getRootProps, getInputProps } = useDropzone();
  const [sign, setSign] = useState(null);
  const width = Dimensions.get("window").width;
  const ismobile = width < 768;
  const { investment } = useParams();
  const { id } = useParams();
  const [loading, setLoading] = useState(true);
  const [showdeletemodal, setShowdeletemodal] = useState(false);
  const [deletemodalname, setDeletemodalname] = useState("");
  const [deleteid, setDeleteid] = useState();
  const [filesup, setFilesup] = useState([]);
  const [uploading, setUploading] = useState(false);
  const [investmentbody, setInvestmentbody] = useState("");
  const [LegalType, setLegalType] = useState(1);

  const toast = useToast();
  function addDoc() {
    DocumentPicker.getDocumentAsync({ type: "*/*" }).then((res) => {
      if (res.type !== "cancel") {
        setUploading(true);
        //filesarray.push(res.file);
        AsyncStorage.getItem("token").then((result) => {
          axios
            .post(
              `${APIadminsURL}/public/api/upload_files`,
              { subscription: id, file: res.file },
              {
                headers: {
                  Authorization: `Bearer ${result}`,
                  "Content-Type": "multipart/form-data",
                },
              }
            )
            .then((resultpost) => {
              axios
                .get(
                  `${APIadminsURL}/public/api/subscription_uploaded_documents/` +
                    id,
                  {
                    headers: { Authorization: `Bearer ${result}` },
                  }
                )
                .then((resultdocs) => {
                  setFilesup([]);
                  setFilesup(resultdocs?.data);
                  setUploading(false);
                });
              
            });
        });
      }
    });
  }
  const prev = () => {
    var data = { id: id, step: 1 };

    AsyncStorage.getItem("token").then((res) => {
      axios
        .post(`${APIadminsURL}/public/api/subscription/update`, data, {
          headers: { Authorization: `Bearer ${res}` },
        })
        .then((res) => {
          if (res.data.status == 200) {
            prevstep();
          }
        });
    });
    //nextstep();
  };

  const handleDeleteDoc = (id, name) => {
    setDeleteid(id);
    setDeletemodalname(name);
  };
  useEffect(() => {
    if (deleteid) {
      setShowdeletemodal(true);
    }
  }, [deleteid]);

  const deletedoc = (id) => {
    AsyncStorage.getItem("token").then((res) => {
      axios
        .post(
          `${APIadminsURL}/public/api/delete_document/` + id,
          {},
          {
            headers: { Authorization: `Bearer ${res}` },
          }
        )
        .then((res) => {
          if (res.data.status === 200) {
            setShowdeletemodal(false);
            setFilesup(filesup.filter((x) => x.id !== id));
            toast.show("Document Deleted", {
              type: "success",
              placement: "bottom",
              duration: 3000,
            });
          }
        });
    });
  };

  const handleNext = () => {
    if (filesup.length > 0 || investmentbody.add_docs !== 1) {
      var data = { id: id, step: 3 };
      AsyncStorage.getItem("token").then((res) => {
        axios
          .post(`${APIadminsURL}/public/api/subscription/update`, data, {
            headers: { Authorization: `Bearer ${res}` },
          })
          .then((res) => {
            if (res.data.status == 200) {
              nextstep();
            }
          });
      });
    } else {
      toast.show("please upload the required documents", {
        type: "danger",
        placement: "bottom",
      });
    }
    // AsyncStorage.getItem("token").then((res) => {
    //   axios
    //     .post(
    //       `${APIadminsURL}/public/api/upload_files`,
    //       { subscription: id, file: res.file },
    //       {
    //         headers: { Authorization: `Bearer ${res}` },
    //         "Content-Type": "multipart/form-data",
    //       }
    //     )
    //     .then((result) => {
    //     });
    // });
  };
  // const onDrop = (acceptedFiles) => {
  //   acceptedFiles.forEach((file) => {
  //     const reader = new FileReader();

  //     reader.onload = () => {
  //       // Do whatever you want with the file contents
  //       const binaryStr = reader.result;
  //       const blob = new Blob([binaryStr]);
  //       filesarray = [...filesarray, blob];
  //     };
  //     reader.readAsArrayBuffer(file);
  //   });
  // };
  // useEffect(() => {
  //   if (acceptedFiles.length > 0) {
  //     onDrop(acceptedFiles);
  //   }
  // }, [acceptedFiles]);
  useEffect(() => {
    AsyncStorage.getItem("token").then((res) => {
      axios
        .get(`${APIadminsURL}/public/api/subscription/` + id, {
          headers: { Authorization: `Bearer ${res}` },
        })
        .then((result) => {
          setLegalType(result?.data.legal_type);
        });
      axios
        .get(`${APIadminsURL}/public/api/investment/` + investment, {
          headers: { Authorization: `Bearer ${res}` },
        })
        .then((investmentx) => {
          setInvestmentbody(investmentx?.data.investment);
        });
      axios
        .get(`${APIadminsURL}/public/api/preview-subscription-documents/` + id, {
          headers: { Authorization: `Bearer ${res}` },
        })
        .then((result) => {
          //alert(id);
          setSign(result.data);
          setLoading(false);

          /*  setSign(result.data);
          setLoading(false); */
          // client.open(result.data[0].sign_url, {
          //   clientId: "cdea4e5f7051f003ef76da45d1fd5500",
          //   skipDomainVerification: true,
          // });
        })
        .catch(() => {
          setLoading(false);
        });
      axios
        .get(
          `${APIadminsURL}/public/api/subscription_uploaded_documents/` + id,
          {
            headers: { Authorization: `Bearer ${res}` },
          }
        )
        .then((resultdocs) => {
          setFilesup(resultdocs?.data);
        });
    });
  }, []);
  if (loading) return <LoadingComp />;
  return (
    <View style={{ marginBottom: 100, backgroundColor: "#f3f3f3" }}>
      <View
        style={{
          backgroundColor: "#fff",
          borderRadius: 15,
          padding: 25,
          minWidth: ismobile ? null : 440,
          zIndex: 9,
        }}>
        <Text style={{ fontFamily: "Roboto_700Bold", fontSize: 16, color: "#333" }}>
          Review Documents
        </Text>
        <Text
          style={{
            fontFamily: "Roboto_400Regular",
            fontSize: 14,
            color: "#333",
            marginVertical: 10,
            marginTop: 20,
            paddingLeft: 10,
          }}>
          Please review the following document(s) before proceeding to the signature phase. In case
          you have any question, please do not hesitate to contact us at contact@s7equity.ca.
        </Text>

        <View style={{ flexDirection: "row", flexWrap: "wrap", marginTop: 30, paddingLeft: 10 }}>
          {sign?.map((item,index) => {
            return <DocListItem  mykey={index+66}  key={index+99}  name={item.name ?? item.name} url={item.previewurl.replace("/public/", "/public/api/")} type={"pdf"} />;
          })}
        </View>
      </View>
      {investmentbody.add_docs === 1 ? (
        <View
          style={{
            backgroundColor: "#fff",
            borderRadius: 15,
            padding: 25,
            minWidth: ismobile ? null : 440,
            zIndex: 9,
            marginTop: 20,
          }}>
          <Text style={{ fontFamily: "Roboto_700Bold", fontSize: 16, color: "#333" }}>
            Upload Documents
          </Text>
          <Text
            style={{
              fontFamily: "Roboto_400Regular",
              fontSize: 14,
              color: "#333",
              marginVertical: 10,
              marginTop: 20,
              paddingLeft: 10,
            }}>
            {LegalType == 1 ? investmentbody.add_docs_message : investmentbody.add_docs_message2}
          </Text>
          <TouchableOpacity
           key={1252}
            disabled={uploading}
            onPress={() => {
              addDoc();
            }}
            // {...getRootProps({ className: "dropzone" })}
            style={{
              width: "98.5%",
              paddingVertical: 20,
              height: "auto",
              backgroundColor: "#f3f4f5",
              justifyContent: "center",
              alignItems: "center",
              alignSelf: "center",
              borderWidth: 2,
              borderColor: "#939393",
              borderStyle: "dashed",
              dashOffset: 20,
              marginTop: 20,
              overflow: "hidden",
            }}>
            {/* <input {...getInputProps()} /> */}
            {!uploading ? (
              <Text style={{ color: "#939393", fontFamily: "Roboto_700Bold" }}>
                Drop your documents here
              </Text>
            ) : (
              <LoadingComp />
            )}
            <View style={{ marginTop: 50, flexDirection: "row", flexWrap: "wrap" }}>
              {filesup.map((file, key) => (
                <View
                key={key+77}
                  style={{
                    height: 40,
                    backgroundColor: "#939393",
                    justifyContent: "center",
                    alignItems: "center",
                    padding: 20,
                    borderRadius: 10,
                    flexDirection: "row",
                    margin: 10,
                    width: "-webkit-fill-available",
                  }}
                 >
                  <Icon name="checkmark-outline" width={20} height={20} fill="#f3f4f5" />
                  <Text style={{ color: "#f3f4f5", fontFamily: "Roboto_700Bold", fontSize: 12 }}>
                    {file.name} • {formatBytes(file.size)}
                  </Text>
                  <TouchableOpacity
                   key={key+852}
                    onPress={() => {
                      handleDeleteDoc(file.id, file.name);
                    }}
                    style={{
                      width: 20,
                      heigh: 20,
                      borderRadius: 50,
                      backgroundColor: "#f00",
                      position: "absolute",
                      top: -10,
                      right: -10,
                    }}>
                    <Icon name="close" width={20} height={20} fill="#fff" />
                  </TouchableOpacity>
                </View>
              ))}
            </View>
          </TouchableOpacity>
        </View>
      ) : null}
      <View
        style={{
          width: "100%",
          alignSelf: "center",
          flexDirection: "row",
          justifyContent: "flex-end",
          borderRadius: 10,
          marginVertical: 20,
        }}>
        <Button
          style={{ marginRight: 10, backgroundColor: "#939393", borderColor: "#939393" }}
          onPress={() => {
            prev();
          }}
          status="success"
          accessoryLeft=<Icon name="arrow-back-outline" fill="#fff" width={6} height={6} />>
          Previous
        </Button>
        <Button
          onPress={() => {
            handleNext();
          }}
          status="success"
          accessoryRight=<Icon name="arrow-forward-outline" fill="#fff" width={6} height={6} />>
          Next
        </Button>
        <Modal
          style={{ width: ismobile ? "100%" : 500, maxWidth: 600 }}
          visible={showdeletemodal}
          backdropStyle={{ backgroundColor: "rgba(0, 0, 0, 0.5)" }}
          onBackdropPress={() => {
            setDeleteid(null);
            setShowdeletemodal(false);
          }}>
          <Card
            style={{
              width: ismobile ? "100%" : 400,
               maxWidth: 600,
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              flexDirection: "column",
              position: "fixed",
              left: "50%",
              transform: "translate(-50%,-50%)",
              top: "50%",
            }}>
              <View style={{ flexDirection: "row", flexWrap: "wrap" }}>
            <Text
              style={{
                fontFamily: "Roboto_700Bold",
                marginVertical: 5,
                flex: 0.8,
                fontSize: 22,
                color: "#8dc641",
              }}>
              Delete File
            </Text>
            <View
              style={{
                flex: 0.2,
              }}>
              <TouchableOpacity
               key={21400}
                onPress={() => {
                  setDeleteid(null);
                  setShowdeletemodal(false);
                }}
                size={"small"}
                appearance="ghost"
                style={{ width: 10, alignSelf: "flex-end" }}>
                <Text>{" "}</Text>
                <Icon name="close-outline" width={20} height={20} style={{ top: 0 }} fill="#aaa" />
              </TouchableOpacity>
            </View>
          </View>
              <View style={{
           
              justifyContent: "center",
              alignItems: "center",
      
             
            }}>
              <RenderHTML source={{ html: `<img  width="100" height="100" src="data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 64 64' aria-labelledby='title' aria-describedby='desc' role='img' xmlns:xlink='http://www.w3.org/1999/xlink'%3E%3Ctitle%3EDelete Circle%3C/title%3E%3Cdesc%3EA line styled icon from Orion Icon Library.%3C/desc%3E%3Ccircle data-name='layer2' cx='32.001' cy='32' r='30' transform='rotate(-45 32.001 32)' fill='none' stroke='%238dc641' stroke-miterlimit='10' stroke-width='2' stroke-linejoin='round' stroke-linecap='round'%3E%3C/circle%3E%3Cpath data-name='layer1' fill='none' stroke='%238dc641' stroke-miterlimit='10' stroke-width='2' d='M42.999 21.001l-22 22m22 0L21 21' stroke-linejoin='round' stroke-linecap='round'%3E%3C/path%3E%3C/svg%3E" alt="Delete Circle" />` }} />
              </View>
            
            
            <Text
              style={{ fontFamily: "Roboto_400Regular", fontSize: 14, color: "#000", margin: 20 }}>
              Do you confirm deleting the file named {deletemodalname} ?
            </Text>
            <View
              style={{
                marginTop: 20,
                margin: 5,
                flexDirection: "row",
                justifyContent: "space-around",
              }}>
              <Button
                status="success"
                style={{ backgroundColor: "#969696", borderColor: "#969696" }}
                onPress={() => {
                  setDeleteid(null);
                  setShowdeletemodal(false);
                }}>
                Cancel
              </Button>
              <Button
                status="success"
                onPress={() => {
                  deletedoc(deleteid);
                }}>
                Confirm
              </Button>
            </View>
          </Card>
        </Modal>
      </View>
    </View>
  );
}

export default SubStep3;
function formatBytes(a, b = 2) {
  if (!+a) return "0 Bytes";
  const c = b < 0 ? 0 : b,
    d = Math.floor(Math.log(a) / Math.log(1024));
  return `${parseFloat((a / Math.pow(1024, d)).toFixed(c))} ${
    ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"][d]
  }`;
}
function DocsToSign({ id, name, sign_url }) {
  const client = new HelloSign();
  function open() {
    client.open(sign_url, {
      clientId: "cdea4e5f7051f003ef76da45d1fd5500",
      skipDomainVerification: true,
    });
  }
  return (
    <TouchableOpacity
      onPress={() => {
        open();
      }}
      style={{
        height: 40,
        backgroundColor: "#939393",
        justifyContent: "center",
        alignItems: "center",
        padding: 20,
        borderRadius: 10,
        flexDirection: "row",
        margin: 10,
      }}
      key={id}>
      <Icon name="file-outline" width={20} height={20} fill="#f3f4f5" />
      <Text style={{ color: "#f3f4f5", fontFamily: "Roboto_700Bold", fontSize: 12 }}>{name}</Text>
    </TouchableOpacity>
  );
}

