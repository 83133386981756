import AsyncStorage from "@react-native-async-storage/async-storage";
import { Button, Text, CheckBox, Spinner, Modal, Card } from "@ui-kitten/components";
import axios from "axios";
import base64 from "base-64";
import { useState, useEffect } from "react";
import { useForm, Controller, useFieldArray } from "react-hook-form";
import { Image, StyleSheet, TextInput, BackHandler, View } from "react-native";
import { useToast } from "react-native-toast-notifications";
import { useNavigate, useSearchParams } from "react-router-native";
import RenderHtml from "react-native-render-html";
import logo from "../../../assets/s7logo_crop.png";
import {APIadminsURL} from "@env";
function LoginPage() {
  const toast = useToast();
  const [searchParams] = useSearchParams();
  const [redirect] = useState(searchParams.get("redirect_id"));
  const [redirect_uri] = useState(searchParams.get("redirect_uri"));
  const [checked, setChecked] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [email, setemail] = useState();
  const [invid, setinv] = useState();
  const [exist, setExist] = useState(false);
  const [welcomeLoginMessage, setWelcomeLoginMessage] = useState("");
  const navigate = useNavigate();
  useEffect(() => {
    axios
      .get(`${APIadminsURL}/api/welcomeLoginMessage`)
      .then((res) => {
        setWelcomeLoginMessage(res.data.welcomeLoginMessage);
      })
      .catch(() => {});
  }, []);
  const storeData = async (value) => {
    try {
      await AsyncStorage.setItem("token", value);
    } catch (e) {
      console.log("exception caught:", e);
    }
  };

  const {
    control,
    handleSubmit,
    formState: { errors },
    setValue,
  } = useForm({
    defaultValues: {
      email: "",
      password: "",
    },
  });

  const onSubmit = (data) => {
    setIsLoading(true);
    axios
      .post(`${APIadminsURL}/api/auth/login`, data)
      .then((res) => {
        storeData(res.data.access_token);
        if (redirect) {
          //alert(redirect);
          navigate("/investments/" + redirect);
        } else if (redirect_uri) {
          //alert(invid);
          navigate("/investments/" + invid);
        } else {
          navigate("/");
        }
      })
      .catch((err) => {
        toast.show(err.response.data.error, {
          type: "danger",
          placement: "bottom",
        });
        setIsLoading(false);
      });
  };

  useEffect(() => {
    if (redirect_uri) {
      console.log(redirect_uri);
      try {
        const res = base64.decode(redirect_uri);
        const ret = res
            .replace("a:3:","")
            .replace("a:2:", "")
            .replace("a:1:", "")
            .replace("s:5:", "")
            .replace(";s:13:", ",")
            .replace(";i", "")
            .replace(";", "")
            .replace(/[a-z][:][0-9][0-9]/, "")
            .replace(";", "")
            .replace("s:2:", ",")
            .replace(";", "")
            .replace('"id"i:','"id":')
            .replace(";", "");
        setValue("email", JSON.parse(ret).email);
        setemail(JSON.parse(ret).email);
        setinv(JSON.parse(ret).investment_id);
        setExist(JSON.parse(ret).exist);
        // eslint-disable-next-line no-unused-vars
      } catch (e) {
        navigate("/login");
      }
    }
  }, []);

  //TODO: add error toast/notification using catch and "err.response.data.error"

  useEffect(() => {
    const backAction = () => {
      navigate(-1);
      return true;
    };

    const backHandler = BackHandler.addEventListener("hardwareBackPress", backAction);

    return () => backHandler.remove();
  }, []);

  const LoadingIndicator = (props) => (
    <View style={{ justifyContent: "center", alignItems: "center", marginRight: 10 }}>
      <Spinner status="basic" size="small" />
    </View>
  );

  return (
    <>
      <View style={styles.container}>
        <View
          style={{
            paddingTop : 15,
            paddingBottom: 15,
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
            width: "80%",
            backgroundColor: "#fff",
            maxWidth: 500,
            height: "auto",
            //maxHeight: 480,
            borderRadius: 20,
            boxShadow: "1px 2px 9px #ddd",
            elevation: 10,
            shadowColor: "#555",
          }}>
          <Image source={logo} style={{ width: "100%", height: 100, resizeMode: "contain",marginTop: 10  }} />
          <View style={{ width: "80%", marginTop: 15 }}>
            <RenderHtml source={{ html: `${welcomeLoginMessage}` }} />
          </View>
          <View style={{ width: "80%", marginTop: 0 }}>
            <Text style={{ fontFamily: "Roboto_400Regular", marginBottom: 4 }}>Email:</Text>
            <View style={{ width: "100%", marginBottom: 20 }}>
              <Controller
                control={control}
                rules={{
                  required: true,
                  // eslint-disable-next-line no-useless-escape
                  pattern: /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/,
                }}
                name="email"
                render={({ field: { onChange, onBlur, value } }) => (
                  <TextInput
                    value={value}
                    onChangeText={onChange}
                    onBlur={onBlur}
                    // onChangeText={(e) => {
                    //   setemail(e);
                    // }}
                    style={{
                      marginBottom: 5,
                      backgroundColor: "#f7f9fc",
                      borderColor: "#e4e9f2",
                      borderWidth: 1,
                      height: 40,
                      borderRadius: 4,
                      padding: 7,
                      width: "100%",
                    }}
                  />
                )}
              />
              {errors.email?.type === "required" ? (
                <Text style={styles.inputerror}>This field is required</Text>
              ) : errors.email?.type === "pattern" ? (
                <Text style={styles.inputerror}>Please provide a valid email address</Text>
              ) : null}
            </View>
            <Text style={{ fontFamily: "Roboto_400Regular", marginBottom: 4 }}>Password:</Text>
            <View style={{ width: "100%", marginBottom: 20 }}>
              <Controller
                control={control}
                rules={{
                  required: true,
                  minLength: 6,
                }}
                name="password"
                render={({ field: { onChange, onBlur, value } }) => (
                  <TextInput
                    secureTextEntry
                    value={value}
                    onBlur={onBlur}
                    // onChangeText={(e) => {
                    //   setPassword(e);
                    // }}
                    onChangeText={onChange}
                    style={{
                      marginBottom: 5,
                      backgroundColor: "#f7f9fc",
                      borderColor: "#e4e9f2",
                      borderWidth: 1,
                      height: 40,
                      borderRadius: 4,
                      padding: 7,
                      width: "100%",
                    }}
                  />
                )}
              />
              {errors.password && <Text style={styles.inputerror}>This field is required</Text>}
            </View>
            <View
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-between",
                marginBottom: 16,
              }}>
              <CheckBox
                checked={checked}
                onChange={() => {
                  setChecked(!checked);
                }}
                status="success">
                {(evaProps) => (
                  <Text style={{ fontFamily: "Roboto_400Regular", marginLeft: 8, fontSize: 12 }}>
                    Remember Me
                  </Text>
                )}
              </CheckBox>
              <Text
                onPress={() => {
                  navigate("/forget-password");
                }}
                status="success"
                style={{ fontSize: 12 }}>
                Forget password?
              </Text>
            </View>
          </View>
          <Button
            disable={isLoading}
            accessoryLeft={isLoading ? <LoadingIndicator /> : null}
            onPress={handleSubmit(onSubmit)}
            style={{ width: 150, marginTop: 12, marginBottom: 1 }}
            status="success">
            {(evaProps) => (
              <Text style={{ fontFamily: "Roboto_500Medium", color: "white" }}>SIGN IN</Text>
            )}
          </Button>
        </View>
      </View>
      <Modal
        style={{ width: "80%", maxWidth: 500, height: 400 }}
        visible={exist}
        backdropStyle={{ backgroundColor: "rgba(0, 0, 0, 0.5)" }}
        onBackdropPress={() => setExist(false)}>
        <Card
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            flexDirection: "column",
            position: "fixed",
            left: "50%",
            transform: "translate(-50%,-50%)",
            top: "50%",
          }}>
          <View
            style={{
              marginTop: 20,
              margin: 5,
              flexDirection: "Column",
              justifyContent: "space-around",
            }}>
              <Text style={{ fontFamily: "Roboto_700Bold", fontSize: 20, marginBottom: 30, color:"#8dc641" }}>
              Account Exists
            </Text>
            <Text style={{ fontFamily: "Roboto_500Medium", fontSize: 14, marginBottom: 30 }}>
              You already have an account, Please Login
            </Text>
            <Button
              status="success"
              onPress={() => {
                setExist(false);
              }}>
              Ok
            </Button>
          </View>
        </Card>
      </Modal>
    </>
  );
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: "#f3f4f6",
    alignItems: "center",
    justifyContent: "center",
  },
  backdrop: {
    backgroundColor: "rgba(0, 0, 0, 0.5)",
  },
  inputerror: {
    fontFamily: "Roboto_400Regular",
    fontSize: 12,
    color: "#f00",
  },
});

export default LoginPage;
