import axios from "axios";
import { useState } from "react";
import { Text, View } from "react-native";
import RenderHTML from "react-native-render-html";
import {APIadminsURL} from "@env";
function PpDumper() {
  const [source, setSource] = useState("");
  axios.get(`${APIadminsURL}/api/PrivacyPolicy`).then((res) => {
    setSource(res.data.agreement);
  });
  return (
    <View style={{ width: "90%", margin: 20, alignSelf:"center" }}>
      <View style={{ width: "100%", alignItems: "center", justifyContent: "center" }}>
        <Text style={{ fontFamily: "Roboto_700Bold", fontSize: 24 }}>Privacy Policy</Text>
      </View>
      <RenderHTML source={{ html: `${source}` }} />
    </View>
  );
}

export default PpDumper;
