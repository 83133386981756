import AsyncStorage from "@react-native-async-storage/async-storage";
import axios from "axios";
import { useEffect, useState } from "react";
import { Icon } from "react-native-eva-icons";
import { MenuProvider, Menu, MenuOptions, MenuOption, MenuTrigger } from "react-native-popup-menu";
import { View, Image, Text, Dimensions, TouchableOpacity } from "react-native-web";
import { Link, useNavigate } from "react-router-native";
import { Button, Card, Modal } from "@ui-kitten/components";
import {APIadminsURL} from "@env";
import logo from "../../assets/s7logo_white.png";
import logo2 from "../../assets/s7logo.png";
import Swal from "sweetalert2";
function Navbar(props) {
  const navigate = useNavigate();
  const [isOpen, setIsOpen] = useState(false);
  const [islogin, setIslogin] = useState(true);
  const [firstname, setFirstname] = useState("");
  const [adress, setAdress] = useState("NA");
  const [aboutUs, setAboutUs] = useState("NA");
  const [phone, setPhone] = useState("NA");
  const [email, setEmail] = useState("NA");
  const [webSite, setWebSite] = useState("NA");
  const windowWidth = Dimensions.get("window").width;
  const aboutus = () => {
   var adressVal = adress;
   var phoneVal = phone;
   var emailVal = email;
 var websiteVal = webSite;
 var aboutusVal = aboutUs;
    Swal.fire({
      title: '',
      icon: '',
      html:
      `<div style="text-align: left;"><h2 style="font-family: 'Roboto_400Regular';">About us</h2><div style="font-size: 14px;font-family: 'Roboto_300Light';">`+aboutusVal+`</div><h2 style="font-family: 'Roboto_400Regular';">Need help?</h2><p style="font-size: 14px;"><span style="display: inline-flex;"><svg width="22" height="22" viewBox="0 0 24 24"><path  fill="#8dc641"   d="M19 4H5a3 3 0 0 0-3 3v10a3 3 0 0 0 3 3h14a3 3 0 0 0 3-3V7a3 3 0 0 0-3-3zm-.67 2L12 10.75 5.67 6zM19 18H5a1 1 0 0 1-1-1V7.25l7.4 5.55a1 1 0 0 0 .6.2 1 1 0 0 0 .6-.2L20 7.25V17a1 1 0 0 1-1 1z"/></svg>&nbsp; <a  href="mailto: `+emailVal+`" style="margin-top: auto;margin-bottom: auto;font-family: 'Roboto_300Light';text-decoration: none;color:inherit">`+emailVal+`</a></span><br><span style="display: inline-flex;"><svg width="22" height="22" viewBox="0 0 24 24"><path  fill="#8dc641" d="M17.4 22A15.42 15.42 0 0 1 2 6.6 4.6 4.6 0 0 1 6.6 2a3.94 3.94 0 0 1 .77.07 3.79 3.79 0 0 1 .72.18 1 1 0 0 1 .65.75l1.37 6a1 1 0 0 1-.26.92c-.13.14-.14.15-1.37.79a9.91 9.91 0 0 0 4.87 4.89c.65-1.24.66-1.25.8-1.38a1 1 0 0 1 .92-.26l6 1.37a1 1 0 0 1 .72.65 4.34 4.34 0 0 1 .19.73 4.77 4.77 0 0 1 .06.76A4.6 4.6 0 0 1 17.4 22zM6.6 4A2.61 2.61 0 0 0 4 6.6 13.41 13.41 0 0 0 17.4 20a2.61 2.61 0 0 0 2.6-2.6v-.33L15.36 16l-.29.55c-.45.87-.78 1.5-1.62 1.16a11.85 11.85 0 0 1-7.18-7.21c-.36-.78.32-1.14 1.18-1.59L8 8.64 6.93 4z"/></svg> &nbsp; <a href="tel: `+phoneVal+`" style="margin-top: auto;margin-bottom: auto;font-family: 'Roboto_300Light';text-decoration: none;color:inherit">`+phoneVal+`</a></span><br><span style="display: inline-flex;"><svg width="22" height="22" viewBox="0 0 24 24">
      
      <path  fill="#8dc641" d="M22 12A10 10 0 0 0 12 2a10 10 0 0 0 0 20 10 10 0 0 0 10-10zm-2.07-1H17a12.91 12.91 0 0 0-2.33-6.54A8 8 0 0 1 19.93 11zM9.08 13H15a11.44 11.44 0 0 1-3 6.61A11 11 0 0 1 9.08 13zm0-2A11.4 11.4 0 0 1 12 4.4a11.19 11.19 0 0 1 3 6.6zm.36-6.57A13.18 13.18 0 0 0 7.07 11h-3a8 8 0 0 1 5.37-6.57zM4.07 13h3a12.86 12.86 0 0 0 2.35 6.56A8 8 0 0 1 4.07 13zm10.55 6.55A13.14 13.14 0 0 0 17 13h2.95a8 8 0 0 1-5.33 6.55z"/></svg>&nbsp; <a href="`+websiteVal+`" style="margin-top: auto;margin-bottom: auto;font-family: 'Roboto_300Light';text-decoration: none;color:inherit"> `+websiteVal+` </a></span><br><span style="display: inline-flex;"><svg width="22" height="22" viewBox="0 0 24 24"><path  fill="#8dc641" d="M12 2a8 8 0 0 0-8 7.92c0 5.48 7.05 11.58 7.35 11.84a1 1 0 0 0 1.3 0C13 21.5 20 15.4 20 9.92A8 8 0 0 0 12 2zm0 17.65c-1.67-1.59-6-6-6-9.73a6 6 0 0 1 12 0c0 3.7-4.33 8.14-6 9.73z"/><path  fill="#8dc641" d="M12 6a3.5 3.5 0 1 0 3.5 3.5A3.5 3.5 0 0 0 12 6zm0 5a1.5 1.5 0 1 1 1.5-1.5A1.5 1.5 0 0 1 12 11z"/>></svg> <span style="margin-top: auto;margin-bottom: auto;font-family: 'Roboto_300Light';"> &nbsp; `+adressVal+`</span></span></p></div>`,
      showCloseButton: true,
      focusConfirm: false,
      confirmButtonColor: '#8dc641',
    })
  };
  useEffect(() => {
    AsyncStorage.getItem("token").then((res) => {
      if (res === null) {
        //navigate("/login");
        setIslogin(false);
      } else {
        axios
          .get(`${APIadminsURL}/public/api/profile`, {
            headers: { Authorization: `Bearer ${res}` },
          })
          .then((res) => {
            setFirstname(res.data.first_name);
          })
          .catch(() => {
            //navigate("/login");
          });
      }
    });
  }, []);
  useEffect(() => {

    
        axios
          .get(`${APIadminsURL}/api/info`)
          .then((res) => {
            if(res.status==200){
            setAboutUs(res.data.data.aboutus);
            setAdress(res.data.data.adress);
            setPhone(res.data.data.phone);
            setWebSite(res.data.data.website);
            setEmail(res.data.data.email);
          }
          })
          .catch(() => {
            //navigate("/login");
          });
      
   
  }, []);
  function logout() {
    AsyncStorage.removeItem("token").then(() => {
      navigate("/login");
    });
  }
  if (windowWidth > 750) {
    return (
      <MenuProvider key={455005}>
        <View
          style={{
            zIndex: 9,
            width: "100%",
            height: 90,
            backgroundColor: "#8DC641",
            alignItems: "center",
            justifyContent: "space-between",
            flexDirection: "row",
            marginBottom: -80,
          }}>
          <View
            style={{
              width: 150,
              padding: 5,
              height: "100%",
              marginLeft: 40,
              alignItems: "center",
              justifyContent: "center",
            }}>
            <Image source={logo} style={{ width: "100%", height: 50, resizeMode: "contain" }} />
          </View>
          {(() => {
          if (islogin) {
            return [
          <View
          key={4501}
            style={{
              width: "40%",
              height: "100%",
              display: "flex",
              flexDirection: "row",
              justifyContent: "center",
              alignItems: "center",
            }}>
            <NavItemWeb mykey={777} title="Dashboard" isActive={props.menuindex === 0} to="/dashboard" />
            <NavItemWeb mykey={4444} title="My Investments" isActive={props.menuindex === 1} to="/investments" />
            <NavItemWeb mykey={5454} title="Documents" isActive={props.menuindex === 2} to="/documents" />
          </View>
          ];
        }
      })()}
          

          
         
<View key={4105} style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                height: 60,
                width: 250,
                flexDirection: "row",
                flexWrap: "wrap-reverse",
                marginRight: 35,
              }}>
                {(() => {
          if (islogin) {
            return [
  <TouchableOpacity
  key={150110}
   onPress={() => aboutus()}
            style={{
          
              justifyContent: "center",
              alignItems: "center",
              marginRight: 15,

              //flexGrow:1
            }}>
            <Icon
              name={"question-mark-circle-outline"}
              width={30}
              height={30}
              fill="#fff"
            />
            
          </TouchableOpacity>,
          <Menu key={455454545}>
          
            <MenuTrigger
            key={455454444545}
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
             
              flexDirection: "row",
              flexWrap: "wrap-reverse",
            
            }}
              >
            
              <svg width="40" height="40" viewBox="0 0 24 24">
                <path
                  fill="#fff"
                  d="M12 2c5.52 0 10 4.48 10 10s-4.48 10-10 10S2 17.52 2 12S6.48 2 12 2zM6.023 15.416C7.491 17.606 9.695 19 12.16 19c2.464 0 4.669-1.393 6.136-3.584A8.968 8.968 0 0 0 12.16 13a8.968 8.968 0 0 0-6.137 2.416zM12 11a3 3 0 1 0 0-6a3 3 0 0 0 0 6z"
                />
              </svg>
              <Text
                style={{
                  marginLeft: 10,
                  fontsize: 8,
                  color: "#fff",
                  fontFamily: "Roboto_700Bold",
                }}>
                Hello, {firstname}
              </Text>
              <Icon
                name="arrow-down"
                width={20}
                height={20}
                style={{ marginLeft: 10 }}
                fill="#fff"
              />
            </MenuTrigger>
            <MenuOptions
            key={4554000645}
              customStyles={{
                optionsContainer: {
                  borderRadius: 10,
                  borderColor: "#cccccc",
                  marginTop: 50,
                  boxShadow: "1px 2px 9px #ddd",
                  elevation: 10,
                  shadowColor: "#555",
                },
              }}>
              <MenuOption key="emails" onSelect={() => navigate("/emails")}>
                <View
                key={4774545}
                  style={{
                    height: 40,
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                  }}>
                  <Icon name="email-outline" width={20} height={20} fill="#000" />
                  <Text style={{ marginLeft: 10, fontFamily: "Roboto_500Medium" }}>Emails</Text>
                </View>
              </MenuOption>
              <MenuOption key="profile" onSelect={() => navigate("/profile")}>
                <View
                  style={{
                    height: 40,
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                  }}>
                  <Icon name="person-outline" width={20} height={20} fill="#000" />
                  <Text style={{ marginLeft: 10, fontFamily: "Roboto_500Medium" }}>Profile</Text>
                </View>
              </MenuOption>
              <MenuOption key="logout" onSelect={() => logout()}>
                <View
                  style={{
                    height: 40,
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                  }}>
                  <Icon name="log-out" width={20} height={20} fill="#f00" />
                  <Text style={{ marginLeft: 10, fontFamily: "Roboto_500Medium", color: "red" }}>
                    Logout
                  </Text>
                </View>
              </MenuOption>
            </MenuOptions>
          </Menu>
            ];
          }else{
            return [
            <Menu key={457895}>
          
            <View
            style={{
              display: "flex",
              justifyContent: "left",
              alignItems: "center",
             
              flexDirection: "row",
              flexWrap: "wrap-reverse",
            
            }}
              >
            
              
              <Text
                style={{
                  fontSize:17,
                  color: "#fff",
                  fontFamily: "Roboto_700Bold",
                  paddingRight:10
                }}>
                Already have an account ?
              </Text>
              <Button
            onPress={() => {
              navigate("/login");
            }}
            style={{ backgroundColor: "#fff",  }}
            status="success">
            {(evaProps) => (
              <Text style={{ fontFamily: "Roboto_500Medium", color: "#8dc641", paddingLeft: 10, paddingRight: 10, }}>
               SIGN IN
              </Text>
            )}
          </Button>
            </View>
            
          </Menu>
            ];
          }
        })()}
          </View>
        </View>
        {props.children}
      </MenuProvider>
    );
  } else if(islogin== false) {
    // mobile navbar
    //alert(islogin);
    return (
      <MenuProvider key={4789445}>
        <View
          style={{
            zIndex: 9,
            width: "100%",
            height: 60,
            backgroundColor: "#8DC641",
            display: "flex",
            justifyContent: "space-between",
            flexDirection: "row",
            marginBottom: -80,
          }}>
           
          <View style={{ width: 150, padding: 5, height: "100%" }}>
            <Image source={logo} style={{ width: "100%", height: 50, resizeMode: "contain" }} />
          </View>
          
          <View
            style={{
              display: "flex",
              justifyContent: "left",
              alignItems: "center",
             
              flexDirection: "row",
              flexWrap: "wrap-reverse",
              marginRight: 10,
            
            }}
              >
            
              
             
              <Button
            onPress={() => {
              navigate("/login");
            }}
            style={{ backgroundColor: "#fff",  }}
            status="success">
            {(evaProps) => (
              <Text style={{ fontFamily: "Roboto_500Medium", color: "#8dc641", paddingLeft: 10, paddingRight: 10, }}>
               SIGN IN
              </Text>
            )}
          </Button>
            </View>
            
        
        </View>
        {props.children}
      </MenuProvider>
    );
  }else {
    // mobile navbar
    return (
      <MenuProvider key={45790013}>
        <View
          style={{
            zIndex: 9,
            width: "100%",
            height: 60,
            backgroundColor: "#8DC641",
            display: "flex",
            justifyContent: "space-between",
            flexDirection: "row",
            marginBottom: -80,
          }}>
          <TouchableOpacity
           key={5452}
            onPress={() => setIsOpen(!isOpen)}
            style={{
              width: 80,
              height: "100%",
              justifyContent: "center",
              alignItems: "center",
            }}>
            <Icon
              name={isOpen ? "close-outline" : "menu-outline"}
              width={40}
              height={40}
              fill="#fff"
            />
          </TouchableOpacity>

          <View style={{ width: 150, padding: 5, height: "100%" }}>
            <Image source={logo} style={{ width: "100%", height: 50, resizeMode: "contain" }} />
          </View>
          <View style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                height: 60,
                width: 100,
                flexDirection: "column",
                flexWrap: "wrap",
              }}>
                 <TouchableOpacity
                    key={998}
                  onPress={() => aboutus()}
            style={{
          
              justifyContent: "center",
              alignItems: "center",
         

              //flexGrow:1
            }}>
            <Icon
              name={"question-mark-circle"}
              width={30}
              height={30}
              fill="#fff"
            />
            
          </TouchableOpacity>
          <Menu>
            <MenuTrigger
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
             
              }}>
              <Icon name="person" width={40} height={40} fill="#fff" />
            </MenuTrigger>
            <MenuOptions
              customStyles={{
                optionsContainer: {
                  borderRadius: 10,
                  borderColor: "#cccccc",
                  marginTop: 50,
                  boxShadow: "1px 2px 9px #ddd",
                  elevation: 10,
                  shadowColor: "#555",
                },
              }}>
              <MenuOption key="emails2" onSelect={() => navigate("/emails")}>
                <View
                  style={{
                    height: 40,
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                  }}>
                  <Icon name="email-outline" width={20} height={20} fill="#000" />
                  <Text style={{ marginLeft: 10, fontFamily: "Roboto_500Medium" }}>Emails</Text>
                </View>
              </MenuOption>
              <MenuOption key="profile2" onSelect={() => navigate("/profile")}>
                <View
                  style={{
                    height: 40,
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                  }}>
                  <Icon name="person-outline" width={20} height={20} fill="#000" />
                  <Text style={{ marginLeft: 10, fontFamily: "Roboto_500Medium" }}>Profile</Text>
                </View>
              </MenuOption>
              <MenuOption key="logout2" onSelect={() => logout()}>
                <View
                  style={{
                    height: 40,
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                  }}>
                  <Icon name="log-out" width={20} height={20} fill="#f00" />
                  <Text style={{ marginLeft: 10, fontFamily: "Roboto_500Medium", color: "red" }}>
                    Logout
                  </Text>
                </View>
              </MenuOption>
            </MenuOptions>
          </Menu>
          </View>
          <Modal
            style={{ width: "100%", maxWidth: 900 }}
            visible={isOpen}
            backdropStyle={{ backgroundColor: "rgba(0, 0, 0, 0.5)" }}
            onBackdropPress={() => setIsOpen(false)}>
            <Card
              style={{
                justifyContent: "flex-start",
                alignItems: "flex-start",
                flexDirection: "column",
                position: "fixed",
                left: 0,
                top: 0,
                width: "100%",
                height: "100%",
                maxWidth: 900,
              }}>
              <TouchableOpacity
                 key={7600}
                style={{ width: 50, height: 50 }}
                onPress={() => {
                  setIsOpen(false);
                }}>
                <Icon name="close" width={36} height={36} style={{ zIndex: 1990 }} fill="#000" />
              </TouchableOpacity>

              <View
                style={{
                  marginTop: 20,
                  margin: 5,
                  flexDirection: "column",
                  justifyContent: "space-around",
                  width: windowWidth,
                }}>
                <View
                  style={{ margin: 0, display: "flex", justifyContent: "center", width: "100%" }}>
                  <Image
                    source={logo2}
                    style={{ width: "80%", height: 100, resizeMode: "contain" }}
                  />
                </View>
                <MenuItem
                mykey={790001}
                  title="Dashboard"
                  icon="layout"
                  isActive={props.menuindex === 0}
                  to="/dashboard"
                />
                <MenuItem
                mykey={12933}
                  title="My Investments"
                  icon="trending-up"
                  isActive={props.menuindex === 1}
                  to="/investments"
                />
                <MenuItem
                mykey={45003}
                  title="Documents"
                  icon="file-text-outline"
                  isActive={props.menuindex === 2}
                  to="/documents"
                />
                <MenuItem
                mykey={52450}
                  title="Emails"
                  icon="email-outline"
                  isActive={props.menuindex === 3}
                  to="/emails"
                />
                <MenuItem
                mykey={790046}
                  title="Settings"
                  icon="settings-2-outline"
                  isActive={props.menuindex === 4}
                  to="/profile"
                />
                <MenuItem
                mykey={65600}
                  title="Logout"
                  icon="log-out-outline"
                  isActive={props.menuindex === 5}
                  to={null}
                  isLogout
                />
              </View>
            </Card>
          </Modal>
        </View>
        {props.children}
      </MenuProvider>
    );
  }


  function MenuItem({ mykey,title, isActive = false, icon, to, isLogout = false }) {
    return (
      <Text
      key={mykey}
        onPress={() => {
          if (isLogout) {
            logout();
          } else {
            navigate(to);
            setIsOpen(false);
          }
        }}
        underlayColor="#ffffff00">
        <View
          style={{
            padding: 20,
            marginTop: 5,
            backgroundColor: `${isActive ? "#F4FCD8" : "#fff"}`,
            borderRadius: 30,
            width: "90%",
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
          }}>
          <Icon fill={`${isActive ? "#8DC641" : "#000000"}`} width={24} height={24} name={icon} />
          <Text
            style={{
              color: `${isActive ? "#8DC641" : "#000"}`,
              fontFamily: `${isActive ? "Roboto_700Bold" : "Roboto_500Medium"}`,
              marginLeft: 10,
            }}>
            {title}
          </Text>
        </View>
      </Text>
    );
  }

  function NavItemWeb({mykey, title, isActive, to }) {
    return (
      <Link
        key={mykey}
        underlayColor="#ffffff00"
        style={{
          height: "100%",
          width: "25%",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          borderBottomColor: `${isActive ? "#256804" : "#ffffff00"}`,
          borderBottomWidth: 6,
          borderTopWidth: 6,
          borderTopColor: "#ffffff00",
        }}
        to={to}>
        <Text
          style={{
            color: `${isActive ? "#256804" : "#fff"}`,
            fontFamily: `${isActive ? "Roboto_700Bold" : "Roboto_500Medium"}`,
            fontSize: 15,
          }}>
          {title}
        </Text>
      </Link>
    );
  }
}

export default Navbar;
