import AsyncStorage from "@react-native-async-storage/async-storage";
import axios from "axios";
import * as DocumentPicker from "expo-document-picker";
import { Icon } from "react-native-eva-icons";

import { useEffect, useState } from "react";
import { Text, Dimensions, StyleSheet, TextInput, TouchableOpacity } from "react-native";
import { View } from "react-native-web";
import { WebView } from "react-native-web-webview";
import { useParams } from "react-router-dom";
import { useNavigate, useSearchParams } from "react-router-native";
import { useToast } from "react-native-toast-notifications";
import DocListItem from "../../../components/documents/doclistitem";
import LoadingComp from "../../../components/loading";
import Card from "../../../components/card";
import CompactCard from "../../../components/compactcard";
import { Button } from "@ui-kitten/components";
import { useForm, Controller } from "react-hook-form";
import useTokenCheck  from "../../../components/useTokenCheck";

import { APIadminsURL } from "@env";
function Payment(props) {
  useTokenCheck();
  const [deleteid, setDeleteid] = useState();
  const [disabledBTN, setdisabledBTN] = useState(false);
  const [filesup, setFilesup] = useState([]);
  const [uploading, setUploading] = useState(false);
  function addDoc() {
    DocumentPicker.getDocumentAsync({ type: "*/*" }).then((res) => {
      if (res.type !== "cancel") {
        setUploading(true);
        setFilesup([]);
        setFilesup([res]);
        setUploading(false);
        //filesarray.push(res.file);
      }
    });
  }
  const handleDeleteDoc = (id, name) => {
    setFilesup([]);
  };
  const onSubmit = (data) => {
    if(filesup.length == 0){
      alert("error")
    }else{
      setdisabledBTN(true);
      data["id"] = id;
      data["status"] = 'sent';
      data["file"]=filesup[0]['file'];
      AsyncStorage.getItem("token").then((res) => {
        axios
          .post(`${APIadminsURL}/public/api/subscription/proofOfPayment`, data, {
            headers: { Authorization: `Bearer ${res}`,"Content-Type": "multipart/form-data", },
          })
          .then((res) => {
            if (res.data.status == 200) {
              navigate("/investments/" + investment );
            }
          });
      });
    }
  };
  const width = Dimensions.get("window").width;
  const { investment, id } = useParams();
  const [approvement, setApprovement] = useState(null);
  const [investmentbody, setInvestmentbody] = useState("");
  const [dataInvest, setDataInvest] = useState("");

  const ismobile = width < 768;
  useEffect(() => {
    AsyncStorage.getItem("token").then((res) => {
      axios
        .get(`${APIadminsURL}/public/api/investment/` + investment, {
          headers: { Authorization: `Bearer ${res}` },
        })
        .then((investmentx) => {
          setInvestmentbody(investmentx?.data.investment);
          setApprovement(investmentx?.data.investment.approvement);

        });
    });
  }, []);

  const [step, setStep] = useState(0);
  const navigate = useNavigate();
  const toast = useToast();

  const [data, setData] = useState({});
  const [visible, setVisible] = useState(false);
  const [sign, setSign] = useState(null);
  const [docs, setDocs] = useState([]);
  const [disabledbtn, setdisabledbtn] = useState(true);
  const [errorSubmit, seterrorSubmit] = useState(true);
  const {
    control,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm({
    defaultValues: {
      date_received: "",
      note: "",
     
    },
  });
  useEffect(() => {
    AsyncStorage.getItem("token").then((res) => {
      if (res !== null) {
        axios
          .get(`${APIadminsURL}/public/api/investment/` + investment, {
            headers: { Authorization: `Bearer ${res}` },
          })
          .then((res) => {
            //res.data.forEach(myFunction);
            setDataInvest(res.data.investment);
          });
      }
    });
  }, []);
  useEffect(() => {
    AsyncStorage.getItem("token").then((res) => {
      axios
        .get(`${APIadminsURL}/public/api/subscription/` + id, {
          headers: { Authorization: `Bearer ${res}` },
        })
        .then((result) => {
          setData(result?.data);
        });
      axios
        .get(`${APIadminsURL}/public/api/subscription_uploaded_documents/` + id, {
          headers: { Authorization: `Bearer ${res}` },
        })
        .then((resultdocs) => {
          setDocs(resultdocs?.data);
        });
    });
  }, []);

  if (!data) return <LoadingComp />;
  return (
    <View style={{ backgroundColor: "#f3f3f3", height: "102%" }}>
      <View
        style={{
          flexDirection: ismobile ? "column" : "row",
          width: "95%",
          alignSelf: "center",
          flexWrap: "wrap",
          justifyContent: "center",
          marginTop: 100,
        }}>
        <View style={{ width: ismobile ? "100%" : 450, alignItems: "center" }}>
          {ismobile ? (
            <CompactCard
              id={investmentbody?.id}
              name={investmentbody?.name}
              status={investmentbody?.status}
              equity_goal={investmentbody?.equity_goal}
              min_invest={investmentbody?.min_invest}
              max_invest={investmentbody?.max_invest}
              closing_date={investmentbody?.closing_date}
              city={investmentbody?.city}
              country={investmentbody?.country}
              street_name={investmentbody?.street_name}
              tags={investmentbody?.tags}
            />
          ) : (
            <Card
              height={500}
              id={investmentbody?.id}
              name={investmentbody?.name}
              status={investmentbody.status}
              equity_goal={investmentbody.equity_goal}
              min_invest={investmentbody.min_invest}
              max_invest={investmentbody.max_invest}
              closing_date={investmentbody.closing_date}
              photo={investmentbody.cover}
              city={investmentbody.city}
              country={investmentbody.country}
              street_name={investmentbody.street_name}
              tags={investmentbody.tags}
            />
          )}
        </View>
        <View
          style={{
            flex: 1,
            backgroundColor: "#fff",
            borderRadius: 15,
            padding: 25,
            zIndex: 9,
          }}>
          <Text style={{ fontFamily: "Roboto_700Bold", fontSize: 22, color: "#8dc641" }}>
            Upload proof of payment
          </Text>
          <Text
          style={{
            fontFamily: "Roboto_400Regular",
            fontSize: 14,
            color: "#333",
            marginVertical: 5,
            marginTop: 20,
            paddingLeft: 10,
          }}>
         Please enter carefully the payment details and upload the document that proves your completed
payment.
        </Text>
        <Text
          style={{
            fontFamily: "Roboto_400Regular",
            fontSize: 14,
            color: "#333",
            marginVertical: 5,
            paddingLeft: 10,
          }}>
          The document should be clear and accurate.
        </Text>
          <View style={{ flexDirection: "row", marginTop: 10 }}>
            <View
              style={{ flexDirection: "column", marginRight: 20, width: "40%", paddingLeft: 10 }}>
              <Text
                style={{
                  fontFamily: "Roboto_500Medium",
                  fontSize: ismobile ? 11 : 14,
                  color: "#333",
                  marginTop: 10,
                }}>
                Amount <Text style={{ color: "#df0101" }}>*</Text>
              </Text>
              
                  <TextInput
                    value={currency(data.commitment)}
                    editable = {false}
                    type="currency"
                    style={{
                      width: "100%",
                      height: 40,
                      borderColor: "#bdbdbd",
                      borderRadius: 5,
                      borderWidth: 1,
                      marginTop: 10,
                      paddingHorizontal: 10,
                    }}
                  />
               
             
            </View>

            <View style={{ flexDirection: "column", marginRight: 10, width: "40%" }}>
              <Text
                style={{
                  fontFamily: "Roboto_500Medium",
                  fontSize: ismobile ? 11 : 14,
                  color: "#333",
                  marginTop: 10,
                }}>
                Issue Date <Text style={{ color: "#df0101" }}>*</Text>
              </Text>
              <Controller
        control={control}
        name="date_received"
        rules={{ required: 'Date is required' }}
        render={({ field }) => (
          <input type="date" style={{
            width: "100%",
            border: "1px solid",
            height: 40,
            borderColor: "#bdbdbd",
            borderRadius: 5,
            borderWidth: 1,
            marginTop: 10,
            paddingRight: 10,
            paddingLeft: 10,
          }} {...field} />
        )}
      />
              

              {errors.date_received && <span style={styles.inputerror}>{errors.date_received.message}</span>}
            </View>
          </View>
          <View style={{ flexDirection: "row", marginTop: 10 }}>
            <View
              style={{ flexDirection: "column", marginRight: 20, width: "85%", paddingLeft: 10 }}>
              <Text
                style={{
                  fontFamily: "Roboto_500Medium",
                  fontSize: ismobile ? 11 : 14,
                  color: "#333",
                  marginTop: 10,
                }}>
                Note to S7 Equity 
              </Text>
              <Controller
                control={control}
               
                name="note"
                render={({ field: { onChange, onBlur, value } }) => (
                  <textarea
                    value={value}
                    onChange={onChange}
                    onBlur={onBlur}
                    style={{
                      width: "100%",
                      height: 60,
                      borderColor: "#bdbdbd",
                      borderRadius: 5,
                      borderWidth: 1,
                      marginTop: 10,
                      paddingHorizontal: 10,
                    }}
                  />
                )}
              />
              {errors.email?.type === "required" ? (
                <Text style={styles.inputerror}>This field is required</Text>
              ) : errors.email?.type === "pattern" ? (
                <Text style={styles.inputerror}>Invalid Email Format</Text>
              ) : null}
            </View>
          </View>
          <View
            style={{
              backgroundColor: "#fff",
              borderRadius: 15,
              marginRight: 20,
              paddingLeft: 10,
               minWidth: ismobile ? null : 440,
              zIndex: 9,
              marginTop: 20,
              width:"85%"
            }}>
            <Text style={{ fontFamily: "Roboto_700Bold", fontSize: 16, color: "#333" }}>
              Upload Document
            </Text>
            <Text
              style={{
                fontFamily: "Roboto_400Regular",
                fontSize: 14,
                color: "#333",
                marginVertical: 10,
                marginTop: 5,
                paddingLeft: 10,
              }}></Text>
            <TouchableOpacity
              disabled={false}
              onPress={() => {
                addDoc();
              }}
              // {...getRootProps({ className: "dropzone" })}
              style={{
                width: "98.5%",
                paddingVertical: 20,
                height: "auto",
                backgroundColor: "#f3f4f5",
                justifyContent: "center",
                alignItems: "center",
                alignSelf: "center",
                borderWidth: 2,
                borderColor: "#939393",
                borderStyle: "dashed",
                dashOffset: 20,
                marginTop: 20,
                overflow: "hidden",
              }}>
              {/* <input {...getInputProps()} /> */}
              {true ? (
                <Text style={{ color: "#939393", fontFamily: "Roboto_700Bold" }}>
                  Click here to upload your document
                </Text>
              ) : (
                <LoadingComp />
              )}
              <View style={{ marginTop: 50, flexDirection: "row", flexWrap: "wrap" }}>
                {filesup.map((file, key) => (
                  <View
                    style={{
                      height: 40,
                      backgroundColor: "#939393",
                      justifyContent: "center",
                      alignItems: "center",
                      padding: 20,
                      borderRadius: 10,
                      flexDirection: "row",
                      margin: 10,
                      width: "-webkit-fill-available",
                    }}
                    key={key}>
                    <Icon name="checkmark-outline" width={20} height={20} fill="#f3f4f5" />
                    <Text style={{ color: "#f3f4f5", fontFamily: "Roboto_700Bold", fontSize: 12 }}>
                      {file.name} • {formatBytes(file.size)}
                    </Text>
                    <TouchableOpacity
                      onPress={() => {
                        handleDeleteDoc(file.id, file.name);
                      }}
                      style={{
                        width: 20,
                        heigh: 20,
                        borderRadius: 50,
                        backgroundColor: "#f00",
                        position: "absolute",
                        top: -10,
                        right: -10,
                      }}>
                      <Icon name="close" width={20} height={20} fill="#fff" />
                    </TouchableOpacity>
                  </View>
                ))}
              </View>
            </TouchableOpacity>
          </View>
        </View>
        <View
        style={{
          width: "100%",
          alignSelf: "center",
          flexDirection: "row",
          justifyContent: "flex-end",
          borderRadius: 10,
          marginVertical: 20,
        }}>
        <Button
          style={{ marginRight: 10,backgroundColor:"#939393",borderColor:"#939393" }}
          onPress={() => {
            navigate(-1);
          }}
          status="success" 
         >
          Cancel
        </Button>
        <Button
         onPress={handleSubmit(onSubmit)}
        disabled={disabledBTN}
          status="success"
       >
          Submit
        </Button>
      </View>
      </View>
      
    </View>
  );
}

export default Payment;
function formatBytes(a, b = 2) {
  if (!+a) return "0 Bytes";
  const c = b < 0 ? 0 : b,
    d = Math.floor(Math.log(a) / Math.log(1024));
  return `${parseFloat((a / Math.pow(1024, d)).toFixed(c))} ${
    ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"][d]
  }`;
}
function BasicInfoLine({ name, value, rowFix = 0.5 }) {
  const width = Dimensions.get("window").width;
  /* const ismobile = width < 768; */
  const ismobile = width < 1150;
  return (
    <Text
      style={{
        fontFamily: "Roboto_700Bold",
        fontSize: 14,
        color: "#969696",
        marginTop: 5,
        marginBottom: 5,
        width: ismobile ? "100%" : "50%",
        marginLeft: 10,
      }}>
      {name}:{" "}
      <Text
        style={{
          fontFamily: "Roboto_400Regular",
          fontSize: 14,
          color: "#969696",
        }}>
        {value}
      </Text>{" "}
    </Text>
  );
}
function currency(num) {
  if (num)
    return num.toLocaleString("en-US", {
      style: "currency",
      currency: "USD",
      maximumFractionDigits: 0,
    });
}
const styles = StyleSheet.create({
  container: {
    minHeight: 192,
  },
  backdrop: {
    backgroundColor: "rgba(0, 0, 0, 0.5)",
  },
  inputerror: {
    fontFamily: "Roboto_400Regular",
    fontSize: 12,
    color: "#f00",
  },
});
