import AsyncStorage from "@react-native-async-storage/async-storage";
import axios from "axios";
import { useState, useEffect } from "react";
import { ScrollView, View, Text, Dimensions, Image } from "react-native";
import Grid from "react-native-grid-component";
import { useNavigate } from "react-router-native";
import img from "../../../assets/mt_folder.png";
import DocumentCard from "../../../components/documents/doccard";
import DocListItem from "../../../components/documents/doclistitem";
import DocSection from "../../../components/documents/docsection";
import DocumentsView from "../../../components/documents/docview";
import LoadingComp from "../../../components/loading";
import {APIadminsURL} from "@env";
import useTokenCheck  from "../../../components/useTokenCheck";

function Documents() {
  useTokenCheck();
  const [data, setData] = useState([]);
  const [categories, setCategories] = useState([]);
  const [folders, setFolders] = useState([]);
  const [isError, setIsError] = useState(false);
  const navigate = useNavigate();
  let cols;
  const windowWidth = Dimensions.get("window").width;
  const width = Dimensions.get("window").width;
  const ismobile = width < 900;
  if (windowWidth < 500) {
    cols = 1;
  } else if (windowWidth >= 500 && windowWidth < 1200) {
    cols = 2;
  } else {
    cols = 3;
  }

 
  useEffect(() => {
    AsyncStorage.getItem("token").then((res) => {
      if (res !== null) {
        axios
          .get(`${APIadminsURL}/public/api/mydocuments`, {
            headers: { Authorization: `Bearer ${res}` },
          })
          .then((res) => {
            setData([...data, res.data]);
          })
          .catch((res) => {
            if (res.response.status === 401) {
              navigate("/login");
            }
            if (res.response.status === 404) {
              setIsError(true);
            }
          });
      }
    });
  }, []);
  if (isError) return <NoDocs />;
  if (data.length === 0 || categories === 0 || folders === 0) return <LoadingComp />;
  return (
    <ScrollView
      contentContainerStyle={{
        marginTop: 100,
        width: "100%",
      }}>
      {data.map((doc) => {
        return Object.entries(doc).map(([key, value]) => {
          return (
            <DocSection key={key+1111}  mykey={key+1111}  name={value["name"]} size={23}>
              {Object.entries(value).map(([key2, value2]) => {
                if (key2 != "name") {
                  return (
                    <DocSection key={key2+22222222} mykey={key2+22222222}  name={value2["name"]}>
                      {Object.entries(value2).map(([key3, value3]) => {
                        if (key3 == "without_folder") {
                          return Object.entries(value3).map(([key4, value4]) => {
                       
                            return (
                              <DocListItem
                              key={key4+4444} 
                              mykey={key4+4444} 
                                idDoc={value4.id}
                                name={value4.display_name ?? value4.name}
                                date={value4.created_at.substring(0, 10)}
                                url={`${APIadminsURL}/public/api` + value4.path.replace("/documents/", "/api_get_document/")}
                                type={value4.extension}
                                size={value4.size}
                              />
                            );
                          });
                        }
                        if (key3 != "name" && key3 != "without_folder") {
                          return (
                            <DocSection key={key3+333} mykey={key3+333} name={value3["name"]} size={15}>
                              {Object.entries(value3).map(([key5, value5]) => {
                               
                                if (key5 != "name") {
                                  return (
                                    <DocListItem
                                    key={key5+5} 
                                    mykey={key5+5} 
                                      idDoc={value5.id}
                                      name={value5.display_name ?? value5.name}
                                      date={value5.created_at.substring(0, 10)}
                                      url={`${APIadminsURL}/public/api` + value5.path.replace("/documents/", "/api_get_document/")}
                                      type={value5.extension}
                                      size={value5.size}
                                    />
                                  );
                                }
                              })}
                            </DocSection>
                          );
                        }
                      })}
                    </DocSection>
                  );
                }
              })}
            </DocSection>
          );

        
        });

        
      })}

      
      <View style={{ height: 100, width: 1 }} />
    </ScrollView>
  );
}

export default Documents;

function NoDocs() {
  return (
    <View
      style={{
        flex: 1,
        justifyContent: "center",
        alignItems: "center",
        backgroundColor: "#f7f8fa",
      }}>
      <Image source={img} style={{ width: 200, height: 200 }} />
      <Text style={{ fontSize: 20, fontFamily: "Roboto_700Bold", color: "#909398" }}>
        S7 Equity has not uploaded any documents yet
      </Text>
      <Text style={{ fontSize: 20, fontFamily: "Roboto_700Bold", color: "#909398", marginTop: 5 }}>
        Documents will appear here
      </Text>
    </View>
  );
}
