import { Text } from "@ui-kitten/components";
import { useParams } from "react-router-native";

function TestParams() {
    let {id} = useParams()
    return ( 
        <>
        <Text>{id} abs</Text>
        </>
     );
}

export default TestParams;