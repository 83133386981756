import AsyncStorage from "@react-native-async-storage/async-storage";
import { Button, Modal, Card } from "@ui-kitten/components";
import axios from "axios";
import { useEffect, useState } from "react";
import { Text, Dimensions, StyleSheet, TouchableOpacity } from "react-native";
import { View } from "react-native-web";
import { WebView } from "react-native-web-webview";
import { useParams } from "react-router-dom";
import HelloSign from "hellosign-embedded";
import { Icon } from "react-native-eva-icons";
import RenderHTML from "react-native-render-html";
import { useNavigate, useSearchParams } from "react-router-native";
import { useToast } from "react-native-toast-notifications";
import Select, { components } from "react-select";
import DocListItem from "../../../components/documents/doclistitem";
import LoadingComp from "../../../components/loading";
import Swal from "sweetalert2";
import {APIadminsURL} from "@env";
var numbrDoc;
function SubStep4({ prevstep, nextstep }) {
  const width = Dimensions.get("window").width;
  /* const ismobile = width < 768; */
  const ismobile = width < 1150;
  const { investment } = useParams();
  const navigate = useNavigate();
  const toast = useToast();

  const { id } = useParams();
  const [data, setData] = useState({});
  const [visible, setVisible] = useState(false);
  const [sign, setSign] = useState(null);
  const [investmentbody, setInvestmentbody] = useState("");
  const [dataInvest, setDataInvest] = useState("");
  const [docs, setDocs] = useState([]);
  const [disabledbtn, setdisabledbtn] = useState(true);
  const [errorSubmit, seterrorSubmit] = useState(true);
  const [signed, setSigned] = useState([]);

  function addsigned(id, name) {
    setSigned([...signed, { id, name }]);
    setSign(sign.filter((item) => item.id !== id));
  }

  const prev = () => {
    var data = { id: id, step: 2 };

    AsyncStorage.getItem("token").then((res) => {
      axios
        .post(`${APIadminsURL}/public/api/subscription/update`, data, {
          headers: { Authorization: `Bearer ${res}` },
        })
        .then((res) => {
          if (res.data.status == 200) {
            prevstep();
          }
        });
    });
    //nextstep();
  };

  const onSubmit = (data) => {
    AsyncStorage.getItem("token").then((res) => {
      axios
        .get(`${APIadminsURL}/public/api/investment/` + investment, {
          headers: { Authorization: `Bearer ${res}` },
        })
        .then((investmentx) => {
          setInvestmentbody(investmentx?.data.investment);
        });
      axios
        .get(`${APIadminsURL}/public/api/subscription-documents/` + id, {
          headers: { Authorization: `Bearer ${res}` },
        })
        .then((result) => {
          //alert(id);
          numbrDoc = result.data.length;
          setSign(result.data);
          setVisible(true);
          window.scrollTo(0, 0);
          //setLoading(false);signature_request_id
          // client.open(result.data[0].sign_url, {
          //   clientId: "cdea4e5f7051f003ef76da45d1fd5500",
          //   skipDomainVerification: true,
          // });
        })
        .catch(() => {
          //setLoading(false);
        });

      //alert("ok");
    });
  };

  useEffect(() => {
    AsyncStorage.getItem("token").then((res) => {
      if (res !== null) {
        axios
          .get(`${APIadminsURL}/public/api/investment/` + investment, {
            headers: { Authorization: `Bearer ${res}` },
          })
          .then((res) => {
            //res.data.forEach(myFunction);
            setDataInvest(res.data.investment);
           
          });
      }
    });
  }, []);
  useEffect(() => {
    AsyncStorage.getItem("token").then((res) => {
      axios
        .get(`${APIadminsURL}/public/api/subscription/` + id, {
          headers: { Authorization: `Bearer ${res}` },
        })
        .then((result) => {
          setData(result?.data);
        });
      axios
        .get(
          `${APIadminsURL}/public/api/subscription_uploaded_documents/` + id,
          {
            headers: { Authorization: `Bearer ${res}` },
          }
        )
        .then((resultdocs) => {
          setDocs(resultdocs?.data);
        });
    });
  }, []);
  function onSubmitFun(params) {
    if (numbrDoc != 0) {
      seterrorSubmit(false);
      setTimeout(() => {
        seterrorSubmit(true);
      }, 3000);
    } else {
      seterrorSubmit(true);
      let data = { id: id, status: "signed", approvement: "under review" };
      AsyncStorage.getItem("token").then((res) => {
        axios
          .post(`${APIadminsURL}/public/api/subscription/update`, data, {
            headers: { Authorization: `Bearer ${res}` },
          })
          .then((res) => {
            if (res.data.status == 200) {
                Swal.fire({
                  title: "Congratulations!",
                  confirmButtonColor: "#8dc641",
                  text: `You are one step away from closing the deal. We will review
                  your subscription documents and revert soon. This should take up to 2 business days. Once
                  approved you can complete your payment and access your shares certificate upon fund
                  reception. Please do not hesitate to reach out if any questions.`,
                  icon: "success",
                  customClass: {
                    container: "swal-text",
                    popup: "swal-text",
                    title: "swal-title",
                    content: "swal-text",
                  },
                }).then((result) => {
                navigate("/dashboard");
              });
            }
          });
      });
    }
  }
  if (!data) return <LoadingComp />;
  return (
    <>
      <View
        style={{
          overflow: "auto",
          backgroundColor: "#ffffff",
          borderRadius: 15,
          padding: 25,
          minWidth: ismobile ? null : 440,
          zIndex: 9,
          
        }}>
        <Text style={{ fontFamily: "Roboto_700Bold", fontSize: 16, color: "#333", flex: 1 }}>
          BASIC INFORMATION
        </Text>
        <View
          style={{
            flex: 1,
            flexDirection: ismobile ? "column" : "row",
            justifyContent: "space-apart",
            height: ismobile ? 60 : 50,
            paddingTop: 20,
            paddingLeft: 10,
          }}>
          <BasicInfoLine
            rowFix={ismobile ? "auto" : 6}
            name="Investment Amount"
            value={data.commitment ? currency(data.commitment) : "NA"}
          />

          <BasicInfoLine
            rowFix={ismobile ? "auto" : 6}
            name="Investor Type"
            value={
              data.investor_type == 1
                ? "Accredited investor"
                : data.investor_type == 2
                ? "Friends, family and business associate"
                : data.investor_type == 3
                ? "others"
                : "NA"
            }
          />
        </View>
        <Text
          style={{
            flex: 1,
            paddingLeft: 10,
            fontFamily: "Roboto_700Bold",
            fontSize: 16,
            color: "#000",
            paddingTop: ismobile ? 40 : 15,
          }}>
          Account Information:
        </Text>
        <View
          style={{
            justifyContent: "space-apart",
            paddingTop: ismobile ? 5 : 10,
            paddingLeft: 10,
            marginLeft: 20,
          }}>
          {(() => {
            if (data.legal_type == 1) {
              return [
                <View
                  style={{
                    flexDirection: ismobile ? "column" : "row",
                  }}>
                  <BasicInfoLine
                    rowFix={ismobile ? "auto" : 0.5}
                    name="Type of Legal Entity"
                    value={
                      data.legal_type == 1
                        ? "Individual"
                        : data.legal_type == 2
                        ? "Organization"
                        : "NA"
                    }
                  />
                  <BasicInfoLine
                    rowFix={ismobile ? 1 : 0.5}
                    name="Subscriber Legal Name"
                    value={
                      (data.legal_first_name ? data.legal_first_name : "") +
                      " " +
                      (data.legal_middle_name ? data.legal_middle_name : "") +
                      " " +
                      (data.legal_last_name ? data.legal_last_name : "")
                    }
                  />
                </View>,

                <View
                  style={{
                    flexDirection: ismobile ? "column" : "row",
                  }}>
                  <BasicInfoLine
                    rowFix={ismobile ? 1 : 0.5}
                    name="Email Address"
                    value={data.email}
                  />
                  <BasicInfoLine
                    rowFix={ismobile ? 1 : 0.5}
                    name="Phone Number"
                    value={data.phone ? data.phone : "NA"}
                  />
                </View>,
                <View
                  style={{
                    flexDirection: ismobile ? "column" : "row",
                  }}>
                  <BasicInfoLine
                    rowFix={ismobile ? 1 : 0.5}
                    name="Country of citizenship"
                    value={data.citizenship_countryName ? data.citizenship_countryName : "NA" }
                  />
                  <BasicInfoLine
                    rowFix={ismobile ? 1 : 0.5}
                    name="Address"
                    value={data.street_line1 || data.city || data.state ? (
                      (data.street_line1 ? data.street_line1 + ", " : "") +
                        
                        (data.city ? data.city + ", " : "") +
                        
                        (data.state ? data.state : "") ?? "") : "NA"
                    }
                  />
                </View>,
              ];
            } else {
              return [
                <View
                  style={{
                    flexDirection: ismobile ? "column" : "row",
                  }}>
                  <BasicInfoLine
                    name="Type of Legal Entity"
                    value={
                      data.legal_type == 1
                        ? "Individual"
                        : data.legal_type == 2
                        ? "Organization"
                        : "NA"
                    }
                  />
                  <BasicInfoLine
                    name="Organization Name"
                    value={data.organization_name ? data.organization_name : "NA"}
                  />
                </View>,
                <View
                  style={{
                    flexDirection: ismobile ? "column" : "row",
                  }}>
                  <BasicInfoLine name="Corporation Number" value={data.tax_id ? data.tax_id : "NA"} />
                </View>,
              ];
            }
          })()}
        </View>

        {(() => {
          if (data.legal_type == 2) {
            return [
              <Text
                style={{
                  fontFamily: "Roboto_700Bold",
                  fontSize: 16,
                  color: "#000",
                  paddingLeft: 10,
                  paddingTop: 15,
                }}>
                Signatory:
              </Text>,
              <View
                style={{
                  overflow: "auto",
                  justifyContent: "space-apart",
                  paddingTop: 10,
                  paddingLeft: 10,
                  marginLeft: 20,
                }}>
                <View
                  style={{
                    flexDirection: ismobile ? "column" : "row",
                  }}>
                  <BasicInfoLine
                    name="Signatory Type"
                    value={
                      data.signatory_type == 1
                        ? "Individual"
                        : data.signatory_type == 2
                        ? "legal entity"
                        : "NA"
                    }
                  />
                </View>
                {(() => {
                  if (data.signatory_type == 2) {
                    return [
                      <View
                        style={{
                          flexDirection: ismobile ? "column" : "row",
                        }}>
                        <BasicInfoLine
                          name="Entity name"
                          value={data.signatory_entity_name ? data.signatory_entity_name : "NA"}
                        />
                        <BasicInfoLine
                          name="Entity title"
                          value={data.signatory_entity_title ? data.signatory_entity_title : "NA"}
                        />
                      </View>,
                    ];
                  }
                })()}
                <View
                  style={{
                    flexDirection: ismobile ? "column" : "row",
                  }}>
                  <BasicInfoLine
                    name={data.signatory_type == 2 ? "contact person name" : "name"}
                    value={
                      (data.signatory_first_name ? data.signatory_first_name : "") +
                      " " +
                      (data.signatory_middle_name ? data.signatory_middle_name : "") +
                      " " +
                      (data.signatory_last_name ? data.signatory_last_name : "")
                    }
                  />
                  <BasicInfoLine
                    name={data.signatory_type == 2 ? "contact person email" : "Email"}
                    value={data.signatory_email ? data.signatory_email : "NA"}
                  />
                </View>
                <View
                  style={{
                    flexDirection: ismobile ? "column" : "row",
                  }}>
                  <BasicInfoLine
                    name={data.signatory_type == 2 ? "contact person phone" : "Phone"}
                    value={data.signatory_phone ? data.signatory_phone : "NA"}
                  />
                </View>
              </View>,
            ];
          }
        })()}
      </View>
      {docs.length != 0 ? (
        <View
          style={{
            backgroundColor: "#ffffff",
            borderRadius: 15,
            padding: 25,
            minWidth: ismobile ? null : 440,
            zIndex: 9,
            marginTop: 20,
          }}>
          <Text style={{ fontFamily: "Roboto_700Bold", fontSize: 16, color: "#333" }}>
            Uploaded Documents
          </Text>
          <View
            style={{
              flexDirection: "column",
              flexWrap: "wrap",
              justifyContent: "space-apart",
              height: ismobile ? null : 150,
              paddingTop: 20,
            }}>
            {docs?.map((doc) => {
              return (
                <DocListItem
                  key={doc.id}
                  width="40%"
                  name={doc.name}
                  url={`${APIadminsURL}/public/api` + doc.path.replace("/documents/", "/api_get_document/")}
                  type={doc.extension}
                  date={doc.created_at.substring(0, 10)}
                  size={doc.size}
                />
              );
            })}
          </View>
        </View>
      ) : null}

      {dataInvest.ownership == "shares" && <View
        style={{
          backgroundColor: "#ffffff",
          borderRadius: 15,
          padding: 25,
          minWidth: ismobile ? null : 440,
          zIndex: 9,
          marginTop: 20,
        }}>
        <Text
          style={{ fontFamily: "Roboto_700Bold", fontSize: 16, color: "#333", paddingBottom: 20 }}>
          Draft Share Certificate
        </Text>
        <WebView
          style={{ width: "100%", height: 500 }}
          source={{
            uri: ismobile
              ? "https://docs.google.com/viewer?embedded=true&url=" +
                `${APIadminsURL}/print/draft/` +
                id
              : `${APIadminsURL}/print/draft/` + id,
          }}
        />
      </View>}
      <View
        style={{
          width: "100%",
          alignSelf: "center",
          flexDirection: "row",
          justifyContent: "flex-end",
          borderRadius: 10,
          marginVertical: 20,
        }}>
        <Button
          style={{ marginRight: 10, backgroundColor: "#939393", borderColor: "#939393" }}
          onPress={() => {
            prev();
          }}
          status="success"
          accessoryLeft=<Icon name="arrow-back-outline" fill="#fff" width={6} height={6} />>
          Previous
        </Button>
        <Button
          onPress={() => {
            onSubmit();
          }}
          status="success">
          Approve and Sign
        </Button>
      </View>
      <Modal
        style={{ width: ismobile ? "90%" : "700px", maxWidth: 800, paddingTop: 24, marginTop: 50,top:10 }}
        visible={visible}
        backdropStyle={styles.backdrop}
        onBackdropPress={() => setVisible(false)}>
        <Card disabled={false}>
          <Text
            style={{ fontFamily: "Roboto_700Bold", fontSize: 20, color: "#8dc641", marginTop: 10 }}>
            Approve and Sign
          </Text>
          <View
            style={{
              paddingLeft:10
            }}>  
            {(() => {
                if (investmentbody && investmentbody.review_message) {
                  return (
              <RenderHTML source={{ html: `${investmentbody?.review_message}` }} />
              );
            }
          })()}
            </View>
      
          <View style={{ flexDirection: "row", flexWrap: "wrap", marginTop: 10 }}>
            {sign?.map((item) => {
              return (
                <DocsToSign
                  key={item.id}
                  id={item.id}
                  name={item.name}
                  sign_url={item.sign_url}
                  signreq={item.signature_request_id}
                  onSign={addsigned}
                />
              );
            })}
            {signed?.map((item) => {
              return (
                <DocsToSign
                  signed
                  key={item.id}
                  id={item.id}
                  name={item.name}
                  sign_url=""
                  signreq=""
                  onSign={addsigned}
                />
              );
            })}
          </View>

          {errorSubmit === false ? (
            <Text style={styles.inputerror}>Please sign all documents.</Text>
          ) : null}
          <View
            style={{
              width: "100%",
              alignSelf: "center",
              flexDirection: "row",
              justifyContent: "flex-end",
              borderRadius: 10,
              marginVertical: 20,
            }}>
            <Button
              onPress={() => setVisible(false)}
              style={{ backgroundColor: "#969696", borderColor: "#969696", marginRight: 10 }}>
              Cancel
            </Button>
            <Button
              onPress={() => {
                onSubmitFun();
              }}
              status="success">
              Submit
            </Button>
          </View>
        </Card>
      </Modal>
    </>
  );
}

export default SubStep4;
function BasicInfoLine({ name, value, rowFix = 0.5 }) {
  const width = Dimensions.get("window").width;
  /* const ismobile = width < 768; */
  const ismobile = width < 1150;
  return (
    <Text
      style={{
        fontFamily: "Roboto_700Bold",
        fontSize: 14,
        color: "#969696",
        marginTop: 5,
        marginBottom: 5,
        width: ismobile ? "100%" : "50%",
        marginLeft: 10,
      }}>
      {name}:{" "}
      <Text
        style={{
          fontFamily: "Roboto_400Regular",
          fontSize: 14,
          color: "#969696",
        }}>
        {value}
      </Text>{" "}
    </Text>
  );
}
function currency(num) {
  if (num)
    return num.toLocaleString("en-US", {
      style: "currency",
      currency: "USD",
      maximumFractionDigits: 0,
    });
}
const styles = StyleSheet.create({
  container: {
    minHeight: 192,
  },
  backdrop: {
    backgroundColor: "rgba(0, 0, 0, 0.5)",
  },
  inputerror: {
    fontFamily: "Roboto_400Regular",
    fontSize: 12,
    color: "#f00",
  },
});
function DocsToSign({ id, name, sign_url, signreq, onSign, signed = false }) {
  const [colorb, setcolorb] = useState(signed ? "#76a736" : "#939393");
  const [nameIcon, setnameIcon] = useState(signed ? "checkmark-circle-outline" : "file-outline");
  const [disabledbtn, setdisabledbtn] = useState(signed);
  const client = new HelloSign();
  function open() {
    client.open(sign_url, {
      clientId: "cdea4e5f7051f003ef76da45d1fd5500",
      skipDomainVerification: true,
      locale: HelloSign.locales.EN_US,
      uxVersion: 2,
    });
  }
  /*  client.on("close", (data) => {
    alert("close");
    
  }); */
  client.on("finish", (data) => {
    //alert("ok");
    //colorbt = "#8dc641";

    numbrDoc = numbrDoc - 1;
    setcolorb("#76a736");
    setnameIcon("checkmark-circle-outline");
    setdisabledbtn(true);
    onSign(id, name);
    AsyncStorage.getItem("token").then((res) => {
      let data = { request_id: signreq, name: name };
      axios
        .post(`${APIadminsURL}/public/api/signature_completed`, data, {
          headers: { Authorization: `Bearer ${res}` },
        })
        .then((resultdocs) => {
        });
    });
  });
  return (
    <TouchableOpacity
      disabled={disabledbtn}
      onPress={() => {
        open();
      }}
      style={{
        height: 40,
        backgroundColor: colorb,
        justifyContent: "center",
        alignItems: "center",
        padding: 20,
        borderRadius: 10,
        flexDirection: "row",
        margin: 10,
      }}
      key={id}>
      <Icon name={nameIcon} width={20} height={20} fill="#f3f4f5" />
      <Text style={{ color: "#f3f4f5", fontFamily: "Roboto_700Bold", fontSize: 12 }}>{name}</Text>
    </TouchableOpacity>
  );
}
