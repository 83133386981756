import { useState } from "react";
import { Text, TouchableOpacity, View } from "react-native";
import { Icon } from "react-native-eva-icons";

function DocSection({ mykey=1,children, name , size=20 }) {
  const [visible, setVisible] = useState(false);
  return (
    <View key={mykey}>
      <TouchableOpacity
      key={mykey+8}
        onPress={() => {
          setVisible(!visible);
        }}
        style={{ margin: 20, flexDirection: "row", alignItems: "center" }}>
        <Icon name={visible ? "arrow-down" : "arrow-right"} width={22} height={22} fill="#767676" />
        <Text
          style={{
            alignSelf: "flex-start",
            fontSize:  size,
            color: "#767676",
            fontFamily: "Roboto_500Medium",
          }}>
          {name}
        </Text>
      </TouchableOpacity>
      {visible ? <View style={{ marginHorizontal: 40 }}>{children}</View> : null}
    </View>
  );
}

export default DocSection;
