import AsyncStorage from "@react-native-async-storage/async-storage";
import { Button, CheckBox } from "@ui-kitten/components";
import axios from "axios";
import { useEffect, useState } from "react";
import { Dimensions, Text, View } from "react-native";
import RenderHTML from "react-native-render-html";
import { useToast } from "react-native-toast-notifications";
import { useParams } from "react-router-dom";
import LoadingComp from "../../../components/loading";
import { Icon } from "react-native-eva-icons";
import Swal from "sweetalert2";
import { APIadminsURL } from "@env";
function SubStep2({ prevstep, nextstep }) {
  const { id } = useParams();
  const [statement, setStatement] = useState("");
  const [checked, setChecked] = useState(false);
  const [loading, setLoading] = useState(true);
  const toast = useToast();
  const width = Dimensions.get("window").width;
  const ismobile = width < 768;
  const prev = () => {
    var data = { id: id, step: 0 };

    AsyncStorage.getItem("token").then((res) => {
      axios
        .post(`${APIadminsURL}/public/api/subscription/update`, data, {
          headers: { Authorization: `Bearer ${res}` },
        })
        .then((res) => {
          if (res.data.status == 200) {
            prevstep();
          }
        });
    });
    //nextstep();
  };
  const onSubmit = () => {
    var data = { id: id, step: 2 };

    if (!checked) {
      Swal.fire({
        title: "To be able to continue, you must agree with the statements.",
        confirmButtonColor: "#8dc641",
        icon: "warning",
        customClass: {
          container: "swal-text",
          popup: "swal-text",
          title: "swal-title",
          content: "swal-text",
          icon: "icon-class",
        },
      });
    } else {
      AsyncStorage.getItem("token").then((res) => {
        axios
          .post(`${APIadminsURL}/public/api/subscription/update`, data, {
            headers: { Authorization: `Bearer ${res}` },
          })
          .then((res) => {
            if (res.data.status == 200) {
              nextstep();
            }
          });
      });
      //nextstep();
    }
  };
  useEffect(() => {
    AsyncStorage.getItem("token").then((res) => {
      axios
        .get(`${APIadminsURL}/api/RiskWarning`, {
          headers: { Authorization: `Bearer ${res}` },
        })
        .then((investmentx) => {
          setStatement(investmentx?.data.agreement);
          setLoading(false);
        });
    });
  }, []);
  if (loading) return <LoadingComp />;
  return (
    <View style={{ marginBottom: 100, backgroundColor: "#f3f3f3" }}>
      <View
        style={{
          backgroundColor: "#fff",
          borderRadius: 15,
          padding: 25,
          minWidth: ismobile ? null : 440,
          zIndex: 9,
        }}>
        <Text
          style={{
            fontFamily: "Roboto_700Bold",
            fontSize: 22,
            color: "#8dc641",
            marginBottom: 20,
          }}>
          Disclaimer
        </Text>
        <View style={{ paddingLeft: 10 }}>
          <RenderHTML style={{ paddingLeft: 10 }} source={{ html: `${statement}` }} />
          <CheckBox
            style={{ marginTop: 20, marginBottom: 16 }}
            checked={checked}
            onChange={() => {
              setChecked(!checked);
            }}
            status="success">
            {(evaProps) => (
              <Text style={{ fontFamily: "Roboto_400Regular", fontSize: 14, marginLeft: 8 }}>
                I have read and understood the aforementioned disclaimer{" "}
              </Text>
            )}
          </CheckBox>
        </View>
      </View>
      <View
        style={{
          width: "100%",
          alignSelf: "center",
          flexDirection: "row",
          justifyContent: "flex-end",
          borderRadius: 10,
          marginVertical: 20,
        }}>
        <Button
          style={{ marginRight: 10, backgroundColor: "#939393", borderColor: "#939393" }}
          onPress={() => {
            prev();
          }}
          status="success"
          accessoryLeft=<Icon name="arrow-back-outline" fill="#fff" width={6} height={6} />>
          Previous
        </Button>
        <Button
          onPress={() => {
            onSubmit();
          }}
          /*  onPress={() => {
            nextstep();
          }} */
          status="success"
          accessoryRight=<Icon name="arrow-forward-outline" fill="#fff" width={6} height={6} />>
          Next
        </Button>
      </View>
    </View>
  );
}

export default SubStep2;
