import AsyncStorage from "@react-native-async-storage/async-storage";
import axios from "axios";
import React, { useState ,useEffect} from 'react';
import  { StyleSheet, View, Text, TouchableOpacity, Alert,Image,ScrollView,Platform  } from 'react-native';
import { Table, TableWrapper, Row, Cell } from 'react-native-table-component';
import {APIadminsURL} from "@env";
import {  Button } from '@ui-kitten/components';
import { useNavigate } from "react-router-native";

import Tooltip from "react-native-walkthrough-tooltip";

function InvestmentSection(props) {
  const navigate = useNavigate();
  const gotoInvestment = (id)=>{
    navigate(`/investments/${id}`);
    //alert(id)
  }
    const [myinvest , setMyinvest] = useState([]);

    useEffect(() => {
        AsyncStorage.getItem("token").then((res) => {
          if (res !== null) {
            axios
              .get(`${APIadminsURL}/public/api/myinvest`, {
                headers: { Authorization: `Bearer ${res}` },
              })
              .then((res) => {
                setMyinvest(res.data)
                if(res.data.length==0){
                  props.voidFunction();
                }
               
                
              })
              .catch((res) => {
                props.voidFunction();
               if (res.response.status === 401) {
              navigate("/login");
            }
            
              });
          } else {
            navigate("/login");
          }
        });
      }, []);
    const [mydata , setMydata] = useState({
        tableHead: ['Project Name', 'Date', 'Statut', 'Investment','Distribution','Manager'],
        widthArr: [270, 145, 145, 145, 145, 180]
      });
     
    const styles = {
        container: { flex: 1, padding: 16, paddingTop: 30, backgroundColor: '#fff' },
        head: { height: 40, backgroundColor: '#00000000',color: '#8dc641' },
        text: { fontFamily: 'Roboto_400Regular',color: "#9e9e9e", },
        row: { flexDirection: 'row', backgroundColor: '#f3f3f37d' ,marginTop:5},
        row2: { flexDirection: 'row', backgroundColor: '#fff' ,marginTop:5},
        btn: { width: 58, height: 18, backgroundColor: '#78B7BB',  borderRadius: 2 },
        btnText: { textAlign: 'center', color: '#fff' },
      header: {marginBottom:5},
      headCellText: {
        fontSize: 16,
        fontWeight: 'bold',
        color: '#8dc641',
        textAlign: 'center',
        // Add any additional styling you want for the header cells
      }
    }
      const element = (data, index) => (
        
        <View
            style={{
             
              flexDirection: "row",
              alignItems: "center",
              margin: 5,
            }}>
           
            <View
              style={{
                justifyContent: "center",
                alignItems: "center",
                flex: 1,
                height: "100%",
              }}>
              <View
                style={{
                  width: 60,
                  height: 60,
                  justifyContent: "center",
                  alignItems: "center",
                  borderRadius: 5,
                }}>
                <Image
          source={{
            uri:  data[1] ? `${APIadminsURL}/public/img/investments/${data[1]}` : "https://images.pexels.com/photos/1396122/pexels-photo-1396122.jpeg?auto=compress&cs=tinysrgb&dpr=1&w=500",
          }}
          style={{    height: "100%",width: 'inherit',  }}
        />
              </View>
            </View>
            <View
              style={{
                alignItems: "center",
                flex: 2,
                height: "50%",
                flexDirection: "column",
                justifyContent: "space-evenly",
              }}>
              <Text
                style={{
                  alignSelf: "flex-start",
                  marginLeft: 10,
                  fontFamily: " Roboto_500Medium,",
                  fontSize: 15,
    color: "#000",
    fontFamily: 'Roboto_500Medium',
                }}>
                  <TouchableOpacity title="dsfdsfdf" onPress={() => gotoInvestment(data[2])}>
                <Text style={{margin:"auto"}}>{data[0]} ➞</Text>
                </TouchableOpacity>
              </Text>
              
            </View>
          </View> 
      );
      function currency(num) {
          return num.toLocaleString("en-US", {
            style: "currency",
            currency: "USD",
            maximumFractionDigits: 0,
          });
      }
      const TextS = (data, index) => (
        
        <Text style={{ fontFamily: 'Roboto_700Bold',
        color: index!=2 ? '#333333':'#8dc641'}}>{index==3 || index==4 ? currency(data):data}</Text>
        
      
  );
  
      const managerImg = (data, index) => (
        
            <View
              style={{
                justifyContent: "center",
                flex: 1,
                height: "100%",
              }}>
              <View
                style={{
                  width: 60,
                  height: 60,
                  justifyContent: "center",
                  alignItems: "center",
                  borderRadius: 5,
                }}>
                   
                    <Tesyt image={data[1]}  nameInvst={data[0]}/>
             
                
              </View>
            </View>
            
          
      );
      const tableData = [];
    for (let i = 0; i <5; i += 1) {
      const rowData = [];
      for (let j = 0; j < 6; j += 1) {
        rowData.push(`${i}${j}`);
      }
      tableData.push(rowData);
    }
  return (
  
    <View style={styles.container}  >
       
    <ScrollView horizontal={true}>
      <View>
        <Table borderStyle={{borderColor: 'transparent'}}>

        <View style={[styles.row2, styles.header]}>
  {mydata.tableHead.map((headerCell, index) => (
    <Cell
      key={index}
      data={headerCell}
      width={mydata.widthArr[index]}
      textStyle={styles.text}
      
    />
  ))}
</View>
                
            
        </Table>
        <ScrollView style={styles.dataWrapper}>
          <Table borderStyle={{borderColor: 'transparent'}}>
          {
            myinvest.map((rowData, index) => (
              <TableWrapper key={index} style={styles.row} widthArr={mydata.widthArr}>
                {
                  Object.entries(rowData).map(([key, cellData], cellIndex) => (
                    <Cell key={cellIndex} width={(mydata.widthArr)[cellIndex]} data={cellIndex === 0 ? element(cellData, index) : (cellIndex === 5 ? managerImg(cellData, index) : TextS(cellData,cellIndex))} textStyle={styles.text}/>
                    
                    ))
                }
              </TableWrapper>
            ))
          }
            
          </Table>
        </ScrollView>
      </View>
    </ScrollView>
  </View>
  );
}

export default InvestmentSection;
function Tesyt({image,nameInvst}) {
    const [visible, setVisible] = useState(false);
    const [showTip, setTip] = useState(false);
    const styles2 ={
      container: {
        flex: 1,
        justifyContent: 'center',
        backgroundColor: '#ecf0f1',
        padding: 40,
      },
      button: {
        padding: 10,
        borderRadius: 4
      }
    }
 
    return ( <Tooltip
        isVisible={showTip}
        content={
          <View>
            <Text> {nameInvst} </Text>
          </View>
        }
        placement="top"
        onClose={() => setTip(false)}
        // below is for the status bar of react navigation bar
        topAdjustment={Platform.OS === 'android' ? -StatusBar.currentHeight : 0}
      >
        <TouchableOpacity
            onPress={() => setTip(true)}
          >
           <Image 
                 title="dsfdsfdf"
          source={{
            uri:   `${APIadminsURL}/public/images/AccountManagers/${image}`,
          }}
          style={{ borderRadius: "50%",   height: "40px",width: '40px',  }}
        />
        </TouchableOpacity>
      </Tooltip>)
  }