import { StatusBar } from 'expo-status-bar';
import { StyleSheet, View } from 'react-native';
import * as eva from '@eva-design/eva';
import { ApplicationProvider, Button, Layout, Text } from '@ui-kitten/components';
export default function Test2() {
  return (
    <ApplicationProvider {...eva} theme={eva.light}>
    
    <View style={styles.container}>
      <Button onPress={()=>{alert("hell yea")}}>press me daddy</Button>
      
      <StatusBar style="auto" />
    </View>
    </ApplicationProvider>
  );
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: '#fff',
    alignItems: 'center',
    justifyContent: 'center',
  },
});
