import * as eva from "@eva-design/eva";
import { ApplicationProvider, Button } from "@ui-kitten/components";
import { StatusBar } from "expo-status-bar";
import { StyleSheet, View } from "react-native";
import { useNavigate } from "react-router-native";
import HelloSign from 'hellosign-embedded';
export default function Test() {
  const navigate = useNavigate();
  const client = new HelloSign();

//   client.open(claimUrl, {
//   clientId: '4bb1513c9b248f2d98ccb8f90fb4eb2978471acff50cc77282560fc418b729f0',
//   skipDomainVerification: true
// });
  return (
    <ApplicationProvider {...eva} theme={eva.light}>
      <View style={styles.container}>
        <Button
          onPress={() => {
            navigate("/three/10");
          }}
        >
          press me
        </Button>

        <StatusBar style="auto" />
      </View>
    </ApplicationProvider>
  );
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: "#fff",
    alignItems: "center",
    justifyContent: "center",
  },
});
