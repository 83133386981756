import MapView, { Marker } from "@alamoweb/react-native-web-mapview";
import "leaflet/dist/leaflet.css";
import { TouchableOpacity, Text, Linking } from "react-native";
import { Icon } from "react-native-eva-icons";

import markerpin from "../../assets/location-pin.png";

function Map({ latitude, longitude }) {
  return (
    <MapView
      initialRegion={{
        latitude: latitude ?? 37.78825,
        longitude: longitude ?? -122.4324,
        latitudeDelta: 0.0922,
        longitudeDelta: 0.0421,
      }}
      style={{ width: "100%", height: "90%" }}>
      <Marker
        coordinate={{ latitude: latitude ?? 37.78825, longitude: longitude ?? -122.4324 }}
        image={markerpin}
        title={"it'sa me mario"}
        description="a place"
        onPress={() => {
          alert(1);
        }}
      />
      <TouchableOpacity
        onPress={() => {
          Linking.openURL(`http://www.google.com/maps/place/${latitude},${longitude}`);
        }}
        style={{
          width: 200,
          height: 50,
          backgroundColor: "#33333399",
          zIndex: 9999,
          borderRadius: 50,
          position: "absolute",
          top: 20,
          right: 20,
          alignItems: "center",
          justifyContent: "center",
          flexDirection: "row",
        }}>
        <Icon name="map-outline" width={20} height={20} fill="#fff" />
        <Text style={{ fontFamily: "Roboto_500Medium", color: "#fff", marginLeft: 5 }}>Open with Google maps
        </Text>
      </TouchableOpacity>
    </MapView>
  );
}

export default Map;
