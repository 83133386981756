import { Spinner } from "@ui-kitten/components";
import { View } from "react-native";

function LoadingComp() {
  return (
    <View
      style={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        height: "100%",
        width: "100%",
        backgroundColor: "#f7f8fa",
      }}>
      <Spinner status="success" />
    </View>
  );
}

export default LoadingComp;
