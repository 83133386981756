import { Button, Text } from "@ui-kitten/components";
import { useNavigate } from "react-router-native";

function CustomCC() {
  const navigate = useNavigate();
  return (
    <>
      <Text>should display on web</Text>
      <Button
        onPress={() => {
          navigate(
            "/signup?redirect_id=eyJlbWFpbCI6InRlc3RAdGVzdC5jb20iLCIgaW52ZXN0bWVudF9pZCI6IjEyMzQ1Njc4OSJ9"
          );
        }}>
        signup page
      </Button>
      <Button
        onPress={() => {
          navigate("/login");
        }}>
        login page
      </Button>
      <Button
        onPress={() => {
          navigate("/forget-password");
        }}>
        forget pass page
      </Button>
      <Button
        onPress={() => {
          navigate("dashboard");
        }}>
        dashboard page
      </Button>
    </>
  );
}

export default CustomCC;
