import AsyncStorage from "@react-native-async-storage/async-storage";
import axios from "axios";
import { Button, Card, Modal } from "@ui-kitten/components";
import { useState, useEffect } from "react";
import { StyleSheet, Text, TouchableOpacity, View, Linking, Image, Dimensions } from "react-native";
import { Icon } from "react-native-eva-icons";
import { WebView } from "react-native-web-webview";
import { MaterialIcons } from "@expo/vector-icons";
import { APIadminsURL } from "@env";
import { useNavigate, useParams } from "react-router-native";

// CommonJS

//pics: ${APIadminsURL}/img/investments/h1.jpg
function DocumentDisplay() {
  const [url, setUrl] = useState("");
  const [type, setType] = useState("");
  const [MyError, setMyError] = useState('');

  useEffect(() => {
    // Get the current URL
    const token = localStorage.getItem("token");

    const currentURL = window.location.href;

    // Parse the URL to extract parameters
    const urlParams = new URLSearchParams(currentURL.split("?")[1]);
    const documentUrl = urlParams.get("url");
    console.log(documentUrl);

    const documentType = urlParams.get("type");
    if (documentUrl) {
      if (documentType) {
        //alert();

        async function fetchData(documentUrl, token) {
          // Create an array to store the promises
          axios({
            url: documentUrl,
            method: "GET",
            responseType: "blob",
            headers: {
              Authorization: `Bearer ${token}`,
            },
          })
            .then((response) => {
              console.log(response);
              const pdfBlob = new Blob([response.data], { type: "application/pdf" });
              const thisurl = URL.createObjectURL(pdfBlob);
              /*                     const thisurl = URL.createObjectURL(response.data);
               */ console.log(thisurl);
              setUrl(thisurl);
              setMyError(null);

            })
            .catch((error) => {
               // alert();
              console.log(error);
              setMyError('Error when getting the file:'+error.message);

              // Handle the error here
            });

          // Wait for all promises to resolve

          // Update the state with the transformed data
        }

        fetchData(documentUrl, token);

        //setMyUrl('test.pdf');
        /*             setVisible(state);
         */
      }
    }

    // Update state with the extracted parameters
    setType(documentType);
  }, []);

  //alert(url);
  if (type === "pdf") {
    if(MyError){
        return <View>{MyError}</View>;
    }else if (url) {
      return <WebView source={{ uri: url }} />;
    }
  }
  if (type === "image") {
    if(MyError){
        return <View>{MyError}</View>;
    }else if (url) {
    return (
      <View style={{ flex: 1 }}>
        <Image source={{ uri: url }} style={{ flex: 1 }} resizeMode="contain" />
      </View>
    );
  }}

  return <WebView source={{ uri: url }} />;
}

export default DocumentDisplay;
