import { Button } from "@ui-kitten/components";
import { LinearGradient } from "expo-linear-gradient";
import { Dimensions, Image, Text, TouchableOpacity, View } from "react-native";
import { Icon } from "react-native-eva-icons";
import { useNavigate } from "react-router-native";
import {APIadminsURL} from "@env";
function Cardsub({
  mykey=1,
  id,
  name,
  status,
  equity_goal,
  min_invest,
  max_invest,
  closing_date,
  photo,
  street_name,
  city,
  country,
  sponsor,
  ROI,
  hold_time,
  types,
  seeall = false,
  height = "100%",
  maxwidth = 400,
  offerings_mode = false,
  viewAll = true,
}) {
  const navigate = useNavigate();
  function currency(num) {
    if (num)
      return num.toLocaleString("en-US", {
        style: "currency",
        currency: "USD",
        maximumFractionDigits: 0,
      });
  }
  function getFormatedStringFromDays(numberOfDays) {
    const years = Math.floor(numberOfDays / 365);
    const months = Math.floor((numberOfDays % 365) / 30);
    const days = Math.floor((numberOfDays % 365) % 30);

    const yearsDisplay = years > 0 ? years + (years === 1 ? " year, " : " years, ") : "";
    const monthsDisplay = months > 0 ? months + (months === 1 ? " month, " : " months, ") : "";
    const daysDisplay = days > 0 ? days + (days === 1 ? " day" : " days") : "";
    const res = yearsDisplay + monthsDisplay + daysDisplay;
    return res.replace(/, $/, "");
  }
  const width = Dimensions.get("window").width;
  const ismobile = width < 768;
  return (
    <TouchableOpacity key={mykey}
      onPress={() => {
        navigate("/investments/" + id);
      }}
      style={{
        width: ismobile ? "90%" : "100%",
        maxWidth: maxwidth,
        minWidth: ismobile ? 400 : 400,
        height: 630,
        alignSelf: "center",
        display: "flex",
        flexDirection: "column",
        marginHorizontal: ismobile ? 10 : 0,

        borderRadius: 15,
        backgroundColor: "#ffffff",
      }}>
      <View key={mykey+55}
        style={{
          width: "100%",
          height: 240,
          borderRadius: 10,
          alignItems: offerings_mode ? "center" : null,
          justifyContent: offerings_mode ? "center" : null,
        }}>
        {offerings_mode && (
          <View  key={mykey+999}
            style={{
              width: "100%",
              height: 80,
              flexDirection: "row",
              justifyContent: "space-between",
              paddingHorizontal: "5%",
              paddingBottom: 10,
            }}>
            <Text style={{ alignSelf: "flex-end", fontFamily: "Roboto_700Bold", fontSize: 18 }}>
              {viewAll ? "Live Offerings" :"" }
            </Text>
            
            <Text
              onPress={() => {viewAll ? navigate("/All_investments") : navigate("/investments/" + id)
              }}
              style={{ alignSelf: "flex-end", fontFamily: "Roboto_400Regular", color: "#a1a1a1" }}>
              {viewAll ? "View All ➞" :"" }
            </Text>
          </View>
        )}
        <Image key={mykey+95}
          source={{
            uri: photo
              ? `${APIadminsURL}/img/investments/` + photo
              : "https://images.pexels.com/photos/1396122/pexels-photo-1396122.jpeg?auto=compress&cs=tinysrgb&dpr=1&w=500  ",
          }}
          style={{
            width: offerings_mode ? "90%" : "100%",
            height: offerings_mode ? 200 : 270,
            borderTopLeftRadius: 10,
            borderTopRightRadius: 10,
            borderBottomLeftRadius: offerings_mode ? 10 : 0,
            borderBottomRightRadius: offerings_mode ? 10 : 0,
          }}
        />

        <View key={mykey+56}
          style={{
            position: "absolute",
            paddingHorizontal: 10,
            height: 30,
            right: 10,
            top: 10,
          }}
        />
      </View>
      <View key={mykey+56548}
        style={{
          width: "90%",
          height: 330,
          marginTop: 50,
          display: "flex",
          justifyContent: "space-evenly",
          flexDirection: "column",
          alignSelf: "center",
          backgroundColor: "#ffffff",
          padding: 10,
        }}>
        <Text
          style={{
            fontFamily: "Roboto_700Bold",
            fontSize: 27,
            color: "#333333",
          }}>
          {name}
        </Text>

        <View  key={mykey+555}
          style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            marginTop: 12,
          }}>
          <Icon
            name="pin-outline"
            width={20}
            height={20}
            fill="#8DC641"
            style={{ paddingRight: 10 }}
          />
          <Text
            style={{
              fontFamily: "Roboto_500Medium",
              color: "#8DC641",
              fontSize: 15,
            }}>
            {street_name ? street_name + ", " : ""}
           
            {city ? city + ", " : ""}
           
            {country ? country  : ""}
          </Text>
        </View>

        {sponsor?.length > 0 && (
          <View key={mykey+9898}
            style={{
              display: "flex",
              flexDirection: "row",
              alignItems: "left",
              padding: 15,
              paddingLeft: 0,
              marginTop: 5,
            }}>
            {sponsor?.map((spon, key) => {
              return [
                <Image
                  key={key}
                  style={{ height: "1.3em", width: "2.7em" }}
                  source={{
                    uri: `${APIadminsURL}/public/img/sponsors/${spon.logo}`,
                  }}
                />,
                <Text
                key={key+25}
                  style={{
                    fontFamily: "Roboto_500Medium",
                    fontSize: 18,
                    paddingRight: "10px",
                    paddingLeft: "10px",
                  }}>
                  {spon.name}
                </Text>
              ];
            })}
          </View>
        )}

        <View key={mykey+9991}
          style={{
            width: "100%",
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            flexDirection: "row",
            height: 50,
            marginTop: 12,
          }}>
          <Text style={{ fontFamily: "Roboto_400Regular", color: "#969696", fontSize: 15, fontWeight: 500 }}>
            Target Investment ROI:
          </Text>
          <Text style={{ fontFamily: "Roboto_500Medium", fontSize: 18 }}>{ROI ? ROI + "%" : "---"}</Text>
        </View>
        <View style={{ width: "100%", borderColor: "#969696", borderBottomWidth: 1 }} />
        <View key={mykey+999100}
          style={{
            width: "100%",
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            flexDirection: "row",
            height: 50,
          }}>
          <Text style={{ fontFamily: "Roboto_400Regular", color: "#969696", fontSize: 15, fontWeight: 500 }}>
            Funding Target:
          </Text>
          <Text style={{ fontFamily: "Roboto_500Medium", fontSize: 18 }}>
            {equity_goal ? currency(equity_goal) : "---"}
          </Text>
        </View>
        <View style={{ width: "100%", borderColor: "#969696", borderBottomWidth: 1 }} />
        <View key={mykey+1009991}
          style={{
            width: "100%",
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            flexDirection: "row",
            height: 50,
          }}>
          <Text style={{ fontFamily: "Roboto_400Regular", color: "#969696", fontSize: 15, fontWeight: 500 }}>
            Targeted Holding Period:
          </Text>
          <Text style={{ fontFamily: "Roboto_500Medium", fontSize: 18 }}>
            {hold_time ? (hold_time == 1 ? hold_time + " month" : hold_time + " months") : "---"}
          </Text>
        </View>
        <View style={{ width: "100%", borderColor: "#969696", borderBottomWidth: 1 }} />
        <View key={mykey+954991}
          style={{
            width: "100%",
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            flexDirection: "row",
            height: 50,
          }}>
          <Text style={{ fontFamily: "Roboto_400Regular", color: "#969696", fontSize: 15, fontWeight: 500 }}>
          Investment Due Date:
          </Text>
          <Text style={{ fontFamily: "Roboto_500Medium", fontSize: 18 }}>{closing_date ? closing_date  : "---"}</Text>
        </View>
      </View>
    </TouchableOpacity>
  );
}

export default Cardsub;
